.box-landing {
    display: grid;
    grid-template-columns: 30% 60%;
    height: 100vh;
    // background: rgba(0, 0, 0, 0.526);
    align-items: center;
    width:100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 10vw 0px 10vw ;
    justify-content: space-between;
    border-left:1px solid rgba(190, 190, 190, 0.179);
    border-right:1px solid rgba(190, 190, 190, 0.179);
    // box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.244), -10px 0px 15px rgba(0, 0, 0, 0.186);
    position: relative;
    overflow-y: hidden;

    .elemento-1, .elemento-2 {
        z-index: 999;
        position: relative;
        margin-top: 70px;
        margin-bottom: 70px;
    }

    


    h3 {
        margin-bottom: 10px;
    }

    h2 {
        font-weight: 600;
        font-size: 47px;
        width: 400px;
        color: rgb(255, 255, 255);
        text-shadow: 3px -3px 0px rgba(248, 248, 248, 0.123);
        
    }

    p {
        font-weight: 600;
        font-size: 20px;
        color: rgb(255, 255, 255);
        text-shadow: 2px -2px 0px rgba(255, 255, 255, 0.123);
        border-top:1px solid rgb(255, 255, 255);
        padding-top: 20px;
        
        
    }
    

     .elemento-2{ 
        padding: 20px;
        
        
        
        

    }


    @media screen and (min-width:0px) and (max-width: 566px) {
        grid-template-columns: 100%;
        overflow-y: scroll;
        width:100%;
        padding: 0px 0vw 0px 0vw;



        .panther-style {
            filter: blur(10px);
            position: absolute;
            height: 100%;
            display: grid;
            background-size: contain !important;
            width:100%;
            width: 100%;
            z-index: 0;
            background-repeat: repeat !important;
            animation: none !important;
        
        }
        



            .height-11 {
                position: absolute;
                display: grid !important;
                right:-2px;
                align-items: center !important;
                color: #fff;
                z-index: 999;
                height: 100%;
                background: #050e45;
                padding: 0 5px 0 5px !important;
                margin: 0!important;
                border: 0px 2px 2px 0px !important;
                font-weight: 300 !important;
                border-radius: 0px 2px 2px 0px !important;
            
                
            }
    
    

        

       


        h2 {
            font-weight: 600;
            font-size:33px;
            width: 100%;
            color: rgb(255, 255, 255);
            text-shadow: 3px -3px 0px rgba(248, 248, 248, 0.123);
            
            margin-bottom: 10px;
             margin-left: 3vw;
            
        }
    
        p {
            font-weight: 400;
            font-size: 14px;
            color: rgb(255, 255, 255);
            text-shadow: 2px -2px 0px rgba(255, 255, 255, 0.123);
            border-top:1px solid rgb(255, 255, 255);
            padding-top: 10px;
             width: 80%;
             margin-left: 3vw;
            
            
            
        }







    }

    @media screen and (min-width:0px) and (max-width: 565px) {
        overflow-x: hidden;

        .elemento-1 {
            width: 100%;
            margin-bottom: 10px;
        }

        .elemento-2 {
            width: 100%;
            margin-top: 10px;
        }

        form {
            display: grid;
            width: 520px;
            border-radius: 19px;
            padding: 10px;
            display: grid !important;
            grid-template-columns: 80% !important;
            justify-content: center !important;
            align-items: center !important;
            transform: scale(0.80);

            
            

        }


    }



    @media screen and (min-width:566px) and (max-width: 992px) {
        grid-template-columns: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        width:100%;

        .elemento-1 {
            width: 100%;
            margin-bottom: 10px;
        }


        
    h2 {
        font-weight: 600;
        font-size: 47px;
        width: 100%;
        color: rgb(255, 255, 255);
        text-shadow: 3px -3px 0px rgba(248, 248, 248, 0.123);
        margin-top: 70px;
        margin-bottom: 10px;
        
    }

    p {
        font-weight: 600;
        font-size: 15px;
        color: rgb(255, 255, 255);
        text-shadow: 2px -2px 0px rgba(255, 255, 255, 0.123);
        border-top:1px solid rgb(255, 255, 255);
        padding-top: 10px;
        
        
    }



    

    }

            .elemento-1 {
                display: grid;
                align-items: center;
                justify-content: center;
            }

        .elemento-2 {
            display: grid;
            align-items: center;
            justify-content: center;
            height: 600px;
            
            background: linear-gradient(to bottom ,#ffffff, #ffffff,#fff,rgb(255, 255, 255), rgba(255, 255, 255, 0.964)), url(./Panther-pattern-7.png) !important;
            border:1px solid rgba(255, 255, 255, 0.591);
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
        }


        form {
            display: grid;
            width: 520px;
            border-radius: 19px;
            padding: 10px;
            display: grid !important;
            grid-template-columns: 80% !important;
            justify-content: center !important;
            align-items: center !important;

            
            

        }


}

.BoxLabelAlert {
   position: relative; 
}

.LabelAlert {
    
    height: 100%;
    width: 100%;
    position: relative;
    bottom:0px;
    left: 0px;
    font-size: 8px;
    line-height: 9px;
    color: rgb(3, 24, 108);
    text-align: center;
    display: grid;
    padding-bottom: 8px;
    align-items: center;
    

}



.content-view {
    background: linear-gradient(to right bottom, #212121, #000) !important;
    position: relative;
    height: 100%;
    display: grid;
}

.panther-style {
    filter: blur(10px);
    position: absolute;
    height: 100%;
    display: grid;
    background-size: contain !important;
    width:100%;
    width: 100%;
    z-index: 0;
    background-repeat: repeat !important;
    animation: Yeah 7500ms ease 0ms infinite alternate-reverse;

}



@keyframes Yeah {

    0% {
        filter: blur(1px);
    }
 
    30% {
        filter: blur(6px);
    }

    100% {
        filter: blur(60px);
    }
    


}

.logo-size {
    height:70px !important;

}


.logo-size-2 {
    height:30px !important;

}


.logo-size-2:hover {
    filter: saturate(20%);
    
    transition: 100ms;
}

.Hovermaster {
    display: grid;
    position: relative;
}


.panther-nav-temp {
    display: grid;
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.052);
    position: absolute;
    z-index: 1999;
    align-items: center;
    grid-template-columns: 30% 30%;
    justify-content:space-between;
    border: 1px solid rgba(255, 255, 255, 0.15);

    p {
        color: rgb(255, 255, 255);
    }
}       






    .input-landing::focus {
        border:1px solid rgb(219, 219, 219) !important;
    }

        .input-landing {



            border-style:none !important;
            border:1px solid rgba(219, 219, 219, 0.42) !important;
            padding: 10px 20px 10px 20px;
            margin-bottom: 10px;
            border-radius:5px;
            height: 50px;
            text-align: right;
            



            
        }

        .box-seleccion-tiempo button:hover {
            // transform: scale(0.90);
            transition: 100ms;
            cursor: pointer;
            background: #5e84e34c;
            
        }

        .box-seleccion-tiempo button {
            border:1px solid rgba(0, 0, 0, 0.144) !important;
            column-gap: 3px;
            justify-content: center;
            align-items: center;
            text-align: center;
            display: grid;
            border-radius: 2px !important;
            transition: 100ms;
            height:30px;
            border-style: none !important;
            color: #0f2f6c;
            cursor: pointer;

        }

        .box-seleccion-tiempo {
            display: grid;
            // grid-template-columns: repeat(12,8.33%);
            
            grid-template-columns: 30% 30% 30%;
            column-gap: 8px;
            width: 100%;
            justify-content: center;
            height:50px;
            align-items: center;
            
        }

.amount-space {
    font-size: 50px;
    text-align: center;
    height: 100px;
}



.amount-space p {
    color :#525f7f;
    font-weight: 300;
    line-height: 25px;
    font-size: 30px !important;
    text-align: center;
    height: 100px;
    display: grid;
    align-items: center;
    border-bottom: 1px solid rgb(233, 233, 233);
    
}


.active-land {
    transform: scale(0.90);
    transition: all 130ms;
    background:#0f2f6c;
    color: #fff !important;
}

.active-land:hover {

    background:#0f2f6c !important;

}


.askme{
    color: #0f2f6c !important;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    line-height: 44px;
}



.btn-resultado {
    background: #5e84e34c !important;
    border-style:none;
    width: 100%;
    padding: 15px 30px 15px 30px;
    color: #0f2f6c !important;
    border:1px solid #0f2f6c;
    border-radius: 2px;
    


} 




.btn-resultado-disabled {
    background: #f9fbff4c !important;
    border-style:none;
    width: 100%;
    padding: 15px 30px 15px 30px;
    color: #0f2f6c !important;
    border:1px solid #0f2f6c;
    border-radius: 2px;
    cursor: block !important;
    


} 

.points {
    white-space: nowrap !important;
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: movemas2 1000ms ease 0ms infinite alternate;
    margin-top: 6px  !important;
    margin-bottom: 1px  !important;
    padding: 0px !important;
    width: 150px !important;
    font-size: 11px !important;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400 !important;
    
}

.anim3 {
    animation: movemas 1999ms ease 0ms infinite alternate;
}

.anim2 {
    animation: movemas 1666ms ease 0ms infinite alternate;
}

.anim1 {
    animation: movemas 1333ms ease 0ms infinite alternate;
}

.suave {
    animation: suavemas 433ms ease 0ms 1 normal;
    transition: all 100ms;
}

.suave-2 {
    animation: suavemas2 633ms ease 0ms 1 normal;
    transition: all 100ms;
}


.suave-3 {
    animation: suavemas2 133ms ease 0ms 1 normal;
    transition: all 100ms;
}

.trans {
    transition: all 100ms;
}
@keyframes suavemas2 {

    from {
        transform:   translateY(20px);
        opacity: 0;
        transition: 100ms;
    }
    to {
        transform:  translateY(0px);
        opacity: 1;
        transition: 100ms;
    }

}

@keyframes suavemas {

    from {
        transform: scale(0.60);
        opacity: 0;
        transition: 100ms;
    }
    to {
        transform: scale(1);
        opacity: 1;
        transition: 100ms;
    }

}

@keyframes movemas2 {
    from {
        color:#b6b6b6
    }
    to {
        color:#00318c
    }
}


@keyframes movemas {
    from {
        opacity: 0.10;
    }
    to {
        opacity: 1;
    }
}


.points little {
    position: absolute;
    right: 0px;

}

.text-panther {
    color:#5e84e396 !important;
    text-align: center;
    font-weight: 300 !important;
    font-size: 17px;
    border-bottom: 1px solid #00318c26;
    padding-bottom: 4px;
}

.cuento-resultado-pre span 
{
    width: 100%;
    font-size: 9px;
    
}

.text-size-3 {
    font-size: 9px !important;
}


.resultado-total {
    text-align: center;
    font-size: 17px;
    animation: colorcool 1533ms ease 0ms infinite alternate;
    
    color: #fff;
    border-radius: 3px;
    margin-top: 10px;
    transition:all 130ms;
}


@keyframes colorcool {
    from {
        background:#00c47f
    }
    to {
        background:#00b92e
    }
}




.resultado-total-2 {
    text-align: center;
    font-size: 15px;
    
    background: #5e84e34c !important;
    color: #00318c;
    border-radius: 3px;
    margin-top: 10px;
}

.input-yeah {
    
    height: 30px;
    grid-template-columns: 60% 40%;
    display: grid;
    margin-bottom: 20px;
    align-items: center;
    position: relative;
    
    
}

.input-yeah label {
    margin:0px;
}

.input-yeah input {
    position: relative;
    width: 100%;
    text-align: center;
    border-style: none;
    border: 1px solid rgba(184, 184, 184, 0.338);
    height: 100%;

    


} 


.height-10 {
    position: absolute;
    display: none !important;
    right:-18px;
    
    color: #fff;
    z-index: 999;
    bottom:-10px;
    background: #050e45;
    padding: 2px 20px 2px 20px;
}

.height-11 {
    position: absolute;
    display: grid !important;
    right:-2px;
    align-items: center !important;
    color: #fff;
    z-index: 999;
    height: 100%;
    background: #004cb6;
    padding: 0 5px 0 5px !important;
    margin: 0!important;
    border: 0px 2px 2px 0px !important;
    font-weight: 700 !important;
    border-radius: 0px 2px 2px 0px !important;
    
}

.cuento-resultado-texto {
    font-size: 8px;
    padding-top: 3px;
}   

.cuento-titulo-resultado {
    margin-top: 10px !important;
}

.btn-panther-cuenta-calculo{
    display: grid;
    bottom:-20px;
    position: absolute;
    right:-20px;
    
    

}

.btn-panther-cuenta-calculo a {
    color: #fff;
    
    background: #3048e5;
    padding: 10px 13px 10px 13px;
    text-align: center;
    border-radius: 2px;
    transition: 150ms;
}

.btn-panther-cuenta-calculo a:hover {
    color: #fff;
    background: #050e45;
    padding: 10px 13px 10px 13px;
    text-align: center;
    border-radius: 2px;
    transition: 150ms;
}

@media screen and (min-width:0px) and (max-width: 566px) {


    
.panther-nav-temp {
    display: grid;
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.052);
    position: absolute;
    z-index: 999;
    align-items: center;
    grid-template-columns: 40% 60%;
    justify-content:space-between;
    border: 1px solid rgba(255, 255, 255, 0.15);

    p {
        color: rgb(255, 255, 255);
        line-height: 12px;
        margin-bottom: 0px;
        border-left: 1px solid #fff;
        padding-left: 10px;
        max-height: 23px;
        

    }
}       



    



}


.box-logo-white {
    
    display: grid;
    justify-content: center;
    height: 100%;
}

.good {
    background: #000;
    position: absolute;
    bottom:20px;
    z-index: 1029;
    padding: 20px;
}


@media screen and (min-width:0px) and (max-width: 992px) {


    
    .good {
        background: #000;
        position: absolute;
        bottom:10px;
        z-index: 1029;
        padding: 10px;
        font-size: 12px;
    }
    
    
    
        
    
    
    
    }


    .BoxGanancias {
        display: grid;
        grid-template-columns: 39% 60%;
        justify-content: space-between;

    } 

    .opacity-0 {
        opacity: 0 !important;
    }