.UltraSidebar {
    display: grid;
    height: 800px;
    border: 1px solid #fff;

}




.UltraSidebar {
    position: relative;
    background: #000;
    width: 100%;
    height: 300px;
    color: #fff;
}

.ultrasidebar-main {
    color: #fff;
    height: 700px;
}



.ultrasidebar-box-container {
    display: grid;
    justify-content: center !important;
    grid-template-columns: 100% ;
    width: 100% !important;
    
}

.ultrasidebar-box {
    display: grid;
    grid-template-columns: 100%;
    background: rgba(49, 80, 180, 0.696);
    border: 1px solid #ffc98387;
    
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 3px 3px 3px !important;
    position: relative;
    z-index: 999999;
    margin-bottom: 10px;
    height: 100%;
    
    
    
}

.nav-link-ultra{
    font-size: 15px;
    color: #fff;
    height: 60px;
    display: grid;
    align-content: center;
    text-align: center;
    background: #0a2187 !important;
    border: 1px solid #ee8805;
    border-radius: 5px;
    filter: saturate(20%);
    transition: all 150ms;
    
    grid-template-columns: 30% 80%;
    


       
    @media screen and (min-width:0px) and (max-width:992px){
        grid-template-columns: 100%;
        p {
            display: none !important;
        }
    }



    i {
        display: grid;
        align-items: center;
        margin: 0px !important;
    }

    
    
    p {
        margin: 0px !important;
        line-height: 17px;
        text-align: left;
        border-left: 1px solid #fff;
        padding-left: 10px;

    }
}

.nav-link-ultra:hover {
    transition: all 150ms;
    i {
        color: #ff9100;
    }

    p {
        color: #ff9100;
    }
    
}

.nav-link-ultra-active {
    transition: all 150ms;
    transform: scale(0.85);
    background: #0a2187 !important;
    filter: saturate(40%);
    border-radius: 5px;
    border: 1px solid #ff9100;
    width: 100%;
    text-align: center;

    display: grid !important;
    align-content: center !important;


    i {
        color: #ff9100;
    }

    p {
        color: #ff9100;
    }
    
}

.nav-ultra {
    display: grid;
    
    grid-template-columns: 100%;
    align-items: center;

}

.list-cap {
    display: grid;
    grid-template-columns: repeat(7, 14%);
    column-gap: 3px;
    justify-content: center;


    @media screen and (min-width:0px) and (max-width:992px){
        grid-template-columns: repeat(7, 14%);
    }
    
}

.ultrasidebar-box-container {
    padding-top: 70px;
    padding-bottom: 10px;
    padding-left: 4px;
    display: grid;
    position: fixed;
    z-index: 999;
    
    @media screen and (min-width:0px) and (max-width:992px){
        position: relative;
        padding-top: 51px;
    }


    

    


}



.display-nav {
    display: grid !important;
}

.hidde-nav {
    display: none !important;
}


