
// .content{
//     overflow-y: hidden !important;
//     overflow-x: hidden !important;
// }



.box-ultradeposito-white {
    
    
  background-size: cover !important;
  background-repeat: repeat !important;
  z-index: 0;
  position: relative !important;
  height: 80vh;
  border: 1px solid #fff !important;
  padding: 40px;
  overflow: hidden !important;

  

@media screen and (min-width:0px) and (max-width:992px) {
  height: 100%;

  padding: 10px;
  
 }
  
  
}



.box-ultradeposito {
    
    
    background-size: cover !important;
    background-repeat: repeat !important;
    z-index: 0;
    position: relative !important;
    height: 80vh;
    border: 1px solid #d5c9a9 !important;
    padding: 40px;
    overflow: hidden !important;

    

  @media screen and (min-width:0px) and (max-width:992px) {
    height: 100%;

    padding: 10px;
    
   }
    
    
}

.ultradeposito {


    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;


}


//// tema blanco

.ultradeposito-white {


  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;


}


.ultradeposito-son {
  background:linear-gradient( to bottom right, rgba(0, 17, 50, 0.978), rgba(1, 8, 30, 0.813), rgb(0, 0, 0)), url('../../../Landing/Danni_Panther01.jpg') !important;
    border-radius: 6px;
    background-size: cover !important;
    background-repeat: repeat !important;
    background-position: 70% 20% !important;
    z-index: 0 !important;
    position: relative !important;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    content: '';
     filter: blur(60px) saturate(60%);
     opacity: 0.90;
    overflow: hidden !important;




    @media screen and (min-width:0px) and (max-width:992px) {
      opacity: 1;
      
    }


    
} 


////// thema blanco

.ultradeposito-son-white {
  background:linear-gradient(to bottom right, rgb(255, 255, 255), rgba(255, 255, 255, 0.81), white), url('../../../Landing/Danni_Panther01.jpg') !important;
    border-radius: 6px;
    background-size: cover !important;
    background-repeat: repeat !important;
    background-position: 70% 20% !important;
    z-index: 0 !important;
    position: relative !important;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    content: '';
     filter: blur(60px) saturate(60%);
     opacity: 0.90;
    overflow: hidden !important;




    @media screen and (min-width:0px) and (max-width:992px) {
      opacity: 1;
      
    }


    
} 




@media screen and (min-width:0px) and (max-width:992px) {
  .main-panther-bg {
    min-height: 1200px;
  }
}




.ultradeposito {
    // background: url('../../../Landing/Danni_Panther01.jpg') !important;
    
    
    
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    content: '';
    // filter: blur(30px) saturate(60%);
    overflow: hidden !important;
    
    
  }
  
  
  
  .ultradeposito::after {
    background: linear-gradient( to bottom right, rgba(0, 17, 50, 0.807), rgba(1, 8, 30, 0.141), rgb(0, 0, 0));
    border-radius: 6px;
    background-size: 150% !important;
    background-repeat: repeat !important;
    z-index: -1 !important;
    position: absolute !important;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    content: '';
    overflow-y: hidden !important;
  }


  .ultradepositowhite::after {
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.96), white, white);
    border-radius: 0px;
    background-size: 150% !important;
    background-repeat: repeat !important;
    z-index: -1 !important;
    position: absolute !important;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    content: '';
    overflow-y: hidden !important;
  }
  


  .header-deposito {
    display: grid;
    grid-template-columns: 15% 85%;

    .box-logo-white {
      display: grid;
        align-items: center;
        border-right: 1px solid rgba(255, 255, 255, 0.42);
        margin-right: 10px;
    }
    
    h2 {
      margin:0 !important;
      
    }

    p {
      padding-bottom:5px !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.254) !important;
      margin-bottom:5px !important;
    }

    span{
      color: #fff;
      letter-spacing: 1px !important;
    }

    strong {
      color: rgb(68, 144, 244);
    }
  }

  .header-deposito-white {
    display: grid;
    grid-template-columns: 15% 85%;

    .box-logo-white {
      display: grid;
        align-items: center;
        border-right: 1px solid rgba(74, 120, 195, 0.586);
        margin-right: 10px;
    }
    
    h2 {
      margin:0 !important;
      color:rgb(23, 42, 148);
      
    }

    p {
      padding-bottom:5px !important;
      border-bottom: 1px solid rgba(74, 120, 195, 0.586) !important;
      margin-bottom:5px !important;
      color:rgb(23, 42, 148);
    }

    span{
      color: rgba(74, 120, 195, 0.586);
      letter-spacing: 1px !important;
    }

    strong {
      color:rgb(23, 42, 148);
    }
  }





  .ultra-depositos-todos-head {
    grid-template-columns: 20% 25% 15% 20% 20%;
    display: grid;
    border-bottom: 1px solid rgba(255, 255, 255, 0.113);
    padding-left: 10px;

    strong {
      color: rgb(68, 144, 244) !important;
      font-weight: 300 !important;
      white-space:nowrap !important;
    }
  }

  @keyframes cara {
    from {
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }



  .ultra-depositos-todos:hover{
      background: rgba(159, 159, 159, 0.081);
      transition: 200ms ease;
  }

  .animacion-maestra {
    animation: cara 1200ms ease 0s 1 normal !important;
    
  }


  .ultra-depositos-todos {
    grid-template-columns: 20% 25% 15% 20% 20%;
    height: 30px;
    
    align-items: center;
    display: grid;
    border-bottom: 1px solid rgba(255, 255, 255, 0.113);
    padding-left: 10px;
    
    

    strong {
      color: rgb(220, 220, 220);
      font-weight: 400;
      white-space:nowrap !important;
    }
  } 

  .ultra-depositos-todos-white {
    grid-template-columns: 20% 25% 15% 20% 20%;
    height: 30px;
    
    align-items: center;
    display: grid;
    border-bottom: 1px solid rgba(74, 120, 195, 0.586);
    padding-left: 10px;
    
    

    strong {
      color: rgb(23, 42, 148);
      font-weight: 400;
      white-space:nowrap !important;
    }
  }


  
  .body-deposito-white {
    display: grid;
    grid-template-columns: 20% 80%;    
    border:1px solid rgba(74, 120, 195, 0.586);
    padding: 20px;
    margin-top:10px;
    

    @media screen and (min-width: 0px) and (max-width:992px) {
      grid-template-columns: 100%; 
      position: relative;
      width:100%;
      


      .box-ultradeposito-white {
        height: 100% !important;

      }


      .ultra-deposito-jimfwhite{

        grid-template-columns: 33% 33% 33%;
        height: 60px;
      }
      .ultra-deposito-jimfwhite button {
        height: 40px;
        
      }

      .cuento-deposito-white{
          grid-template-columns: 100%;
       }



    }
    
  } 




  .body-deposito {
    display: grid;
    grid-template-columns: 20% 80%;    
    border:1px solid rgba(255, 255, 255, 0.137);
    padding: 20px;
    margin-top:10px;
    

    @media screen and (min-width: 0px) and (max-width:992px) {
      grid-template-columns: 100%; 
      position: relative;
      width:100%;
      


      .box-ultradeposito {
        height: 100% !important;

      }


      .ultra-deposito-jimf{

        grid-template-columns: 33% 33% 33%;
        height: 60px;
      }
      .ultra-deposito-jimf button {
        height: 40px;
        
      }

      .cuento-deposito{
          grid-template-columns: 100%;
       }



    }
    
  } 


  .ultra-deposito-jimfwhite {
    display: grid;
    height: 100%;
    position: relative;
    grid-template-columns:  100%;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    row-gap: 3px;
    button {
      border-style: none !important;
      background: #fff;
      color: rgb(23, 42, 148);
      line-height: 10px;
      border:1px solid #0b47d472 !important;
      border-radius: 1px;
      position: relative !important;
      transition: all 100ms ease;
      outline:none !important;
    }

    button:hover {
      background: #3f85f641;
      transform: scale(0.95);
      transition: all 100ms ease;
      outline:none !important;
    }

  } 

  .ultra-deposito-jimf {
    display: grid;
    height: 100%;
    position: relative;
    grid-template-columns:  100%;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    row-gap: 3px;
    button {
      border-style: none !important;
      background: #ffffff16;
      color: #fff;
      line-height: 10px;
      border:1px solid rgba(255, 255, 255, 0.122) !important;
      border-radius: 1px;
      position: relative !important;
      transition: all 100ms ease;
      outline:none !important;
    }

    button:hover {
      background: #ffffff16;
      transform: scale(0.95);
      transition: all 100ms ease;
      outline:none !important;
    }

  } 


  .active-deposit-jimfwhite {
    background: rgb(23, 42, 148) !important;
    position: relative !important;
    transition: all 100ms ease;
    transform: none !important;
    z-index: 0;
    outline:none !important;
    color:#fff !important;
    
  }

  .active-deposit-jimf {
    background: #031a3464 !important;
    position: relative !important;
    transition: all 100ms ease;
    transform: none !important;
    z-index: 0;
    outline:none !important;
  }


  .active-deposit-jimfwhite::after {
    background: linear-gradient( to right, rgba(0, 0, 0, 0.304), rgb(3, 45, 119));
    position: absolute !important;
    content:'';
    height: 6%;
    width: 100%;
    z-index: 0;
    bottom:0;
    right:0;
    outline:none !important;
  } 

  
  .active-deposit-jimfwhite::before {
    background: linear-gradient( to left, rgba(0, 0, 0, 0.304), rgba(3, 46, 119, 0.924));
    position: absolute !important;
    content:'';
    height: 100%;
    width: 100%;
    z-index: -1;
    bottom:0;
    right:0;
    animation: selection-deposit 1000ms ease 0ms infinite alternate; 
  } 

  .active-deposit-jimf::after {
    background: linear-gradient( to right, rgba(0, 0, 0, 0.304), rgb(3, 45, 119));
    position: absolute !important;
    content:'';
    height: 6%;
    width: 100%;
    z-index: 0;
    bottom:0;
    right:0;
    outline:none !important;
  } 

  
  .active-deposit-jimf::before {
    background: linear-gradient( to left, rgba(0, 0, 0, 0.304), rgba(3, 46, 119, 0.924));
    position: absolute !important;
    content:'';
    height: 100%;
    width: 100%;
    z-index: -1;
    bottom:0;
    right:0;
    animation: selection-deposit 1000ms ease 0ms infinite alternate; 
  } 

  @keyframes selection-deposit {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


  // .ultra-deposito-jimf-info {
  //   overflow-y: scroll !important; 
  //   height: 300px !important;
  // }
 
  .scroll-window-jimf-retiro {
    overflow-y: scroll !important; 
    height: 300px !important;

    
    @media screen and (min-width: 0px) and (max-width:992px) {

      height: 100% !important;

    }
  }

  .window-jimf-retiro {
    
    height: 305px !important;

    @media screen and (min-width: 0px) and (max-width:992px) {

      height: 100% !important;

    }
  }

  .ultra-deposito-jimf-info {
    display: grid;
    


    @media screen and (min-width: 0px) and (max-width:664px) {

      justify-content: center;

    }


    
  }

  



  ///// transferencia internacional ////// 

  .cuento-deposito {
    border:1px solid rgb(87, 87, 87);
    animation: entrando-jimf 250ms ease 0ms 1 normal;
    


  } 

  .cuento-deposito-white {
    border:1px solid rgb(23, 42, 148);
    animation: entrando-jimf 250ms ease 0ms 1 normal;
    


  } 

  @keyframes entrando-jimf {
    from {
        opacity: 0;
        transform: scale(0.97);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .form-deposito {
    border:1px solid rgb(87, 87, 87);
    animation: entrando-jimf 400ms ease 0ms 1 normal;
    

    @media screen and( min-width:0px) and (max-width:992px) {
      height: 100%;
      position:relative !important;
      
    }
    

  }

  .form-deposito-white {
    border:1px solid rgb(23, 42, 148);
    animation: entrando-jimf 400ms ease 0ms 1 normal;
    

    @media screen and( min-width:0px) and (max-width:992px) {
      height: 100%;
      position:relative !important;
      
    }
    

  }

  .box-dual {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 12px;
    justify-content: center;


 @media screen and (min-width:0px) and (max-width:992px) {
  grid-template-columns: 100%;
  row-gap: 12px;
  
  
 }


    
  } 


  


  .window-select-tipe {
    border:1px solid rgb(87, 87, 87);
    display: grid;
    grid-template-columns: 100%;
    width: 97.5%;
    text-align: center;
    align-items: center;
    margin: 10px auto;
    animation: entrando-jimf 1200ms ease 0ms 1 normal;
    background:linear-gradient( to right,  rgba(0, 14, 31, 0.729), #002254a1);

    h1 {
      margin: 10px 0px;
    }
  } 


  .cuento-deposito {
    grid-template-columns: 50% 50%;
    display: grid;
    h1 {
      font-size: 20px;
      margin: 0;
    }

    h5 {
      grid-template-columns: 100%;
      
      font-size: 10px;
      margin: 0;
      display: grid;
      align-items: center;

      strong {
        color :#4490f4 !important
      }
    }
  } 


  .cuento-deposito-white {
    grid-template-columns: 50% 50%;
    display: grid;
    h1 {
      font-size: 20px;
      margin: 0;
      color:rgb(23, 42, 148);
    }

    p {
        color:rgb(23, 42, 148);
    }

    h5 {
      grid-template-columns: 100%;
      
      font-size: 10px;
      margin: 0;
      display: grid;
      align-items: center;

      strong {
        color :#4490f4 !important
      }
    }
  } 



  .g-jimf-100 {
    grid-template-columns: 100% !important;
  }


  .box-cuento-deposito-white {
    display: grid;
    padding: 15px 15px;
    font-size: 11px;
    
    @media screen and (min-width:0px) and (max-width:992px) {
      grid-template-columns: 50% 50%;
    }

      h5 {
        color: rgb(23, 42, 148)
      }

    strong {
      font-size: 12px;
      font-weight: 300 !important;
      
    }

    small {
      font-size: 12px;
      font-weight: 500 !important;
      color :rgb(112, 138, 253) !important;
      
    }

  } 


  .box-cuento-deposito {
    display: grid;
    padding: 15px 15px;
    font-size: 11px;
    
    @media screen and (min-width:0px) and (max-width:992px) {
      grid-template-columns: 50% 50%;
    }


    strong {
      font-size: 12px;
      font-weight: 300 !important;
      
    }

    small {
      font-size: 12px;
      font-weight: 500 !important;
      color :rgb(112, 138, 253) !important;
      
    }

  } 

  .cool-left {
    border-left: 1px solid rgb(112, 138, 253);
    background:linear-gradient( to left, rgba(4, 25, 54, 0.254), rgba(8, 32, 74, 0.419));
  }

  .cool-left-white{
    border-left: 1px solid rgba(74, 120, 195, 0.586);
    background:rgba(233, 233, 233, 0.19);
    box-shadow:  0px 0px 10px rgba(0, 0, 0, 0.27);
  }

  .input-simples-jimf-white {
    display: grid;
    grid-template-columns: 100%;
    color: rgba(74, 120, 195, 0.586);
    
    margin:5px 0px !important;

    span {
      padding: 2px 10px;
      font-size: 10px;
      color: rgb(187, 187, 187);
    }


    label {
      margin:0;
      color:#708afd;
      padding: 0px 10px;
    }

    input {
      border-style: none;
      background:rgba(233, 233, 233, 0.863);
      color: rgba(74, 120, 195, 0.586);
      font-size: 20px;
      position: relative;
      display: grid;
      z-index: 0 !important;
      width: 100%;
      padding: 3px 10px;
      border-bottom:1px solid #4490f4;
    }

  }

  .input-simples-jimf {
    display: grid;
    grid-template-columns: 100%;
    color: #fff;
    
    margin:5px 0px !important;

    span {
      padding: 2px 10px;
      font-size: 10px;
      color: rgb(187, 187, 187);
    }


    label {
      margin:0;
      color:#708afd;
      padding: 0px 10px;
    }

    input {
      border-style: none;
      background:rgba(0, 0, 0, 0.215);
      color: #fff;
      font-size: 20px;
      position: relative;
      display: grid;
      z-index: 0 !important;
      width: 100%;
      padding: 3px 10px;
      border-bottom:1px solid #4490f4;
    }

  }

  .box-class {
    display: grid;
    position: relative;
    width: 100%;

  }

  .box-class-file {
    display: grid;
    position: relative;
    width: 100%;

  }

  

  .box-class::after {
    background: #122d71;
    display: grid;
    width: 10%;
    height:100%;
    top:0;
    right:0;
    position:absolute;
    z-index:2;
    content:'$';
    justify-content: center;
    align-items: center;
    font-size: 17px;

  } 



  .input-simples-jimf-file {
    display: grid;
    grid-template-columns: 100%;
    color: #fff;
    
    margin:15px 0px !important;

    span {
      padding: 2px 10px;
      font-size: 10px;
      color: rgb(187, 187, 187);
    }


    label {
      margin:0;
      color:#708afd;
      padding: 0px 10px;
    }

    input {
      border-style: none;
      background:rgba(0, 0, 0, 0.215);
      color: rgb(160, 160, 160);
      font-size: 10px;
      height: 30px;
      align-items: center !important;
      text-align: center !important;
      justify-content: center;
      position: relative;
      display: grid !important;
      z-index: 0 !important;
      width: 100%;
      padding: 3px 10px;
      border-bottom:1px solid #4490f4;
    } 


    input[type=file]::file-selector-button {
      color: #fff;
      padding: .2em .4em;
      border-radius: .2em;
      background-color: #022967;
      transition: 120ms;
      border-radius: 0!important;
      border-style: none;
    }
    
    input[type=file]::file-selector-button:hover {
      background-color: #81ecec;
      border-style: none;
      border-radius: 0!important;
      transition: 120ms;
    }

  }


  
  .input-simples-jimf-file-white {
    display: grid;
    grid-template-columns: 100%;
    color: #fff;
    
    margin:15px 0px !important;

    span {
      padding: 2px 10px;
      font-size: 10px;
      color: rgb(187, 187, 187);
    }


    label {
      margin:0;
      color:#708afd;
      padding: 0px 10px;
    }

    input {
      border-style: none;
      background:rgba(233, 233, 233, 0.863);
      color: rgb(160, 160, 160);
      font-size: 10px;
      height: 30px;
      align-items: center !important;
      text-align: center !important;
      justify-content: center;
      position: relative;
      display: grid !important;
      z-index: 0 !important;
      width: 100%;
      padding: 3px 10px;
      border-bottom:1px solid #4490f4;
    } 


    input[type=file]::file-selector-button {
      color: #fff;
      padding: .2em .4em;
      border-radius: .2em;
      background-color: #022967;
      transition: 120ms;
      border-radius: 0!important;
      border-style: none;
    }
    
    input[type=file]::file-selector-button:hover {
      background-color: #81ecec;
      border-style: none;
      border-radius: 0!important;
      transition: 120ms;
    }

  }



  
  .submit-jimf {
    background: #031a3464 !important;
    position: relative !important;
    transition: all 100ms ease;
    transform: none !important;
    z-index: 0;
    color: #fff;
    border-style: none;
    border-style:none;
    padding: 10px 10px;
    border-radius:3px;
    width: 90%;
    margin-left:auto !important;
    margin-right:auto !important;
    display: grid;
    border:1px solid rgb(43, 72, 132) ;
    border-bottom:1px solid rgba(0, 0, 0, 0);
    font-size: 12px;
    outline: none !important;
    margin-bottom: 10px !important;
    height: 40px !important;
    white-space: nowrap !important;


  }


  
  .submit-jimf:focus {
    background: #031a3464 !important;
    
  }

  .submit-jimf::after {
    background: linear-gradient( to right, rgba(80, 165, 245, 0.681), rgb(4, 34, 86));
    position: absolute !important;
    content:'';
    height: 7%;
    width: 100%;
    z-index: 0;
    bottom:0;
    right:0;
    border-radius: 0px 0px 1px 1px;
    
  } 

  
  .submit-jimf::before {
    background: linear-gradient( to left, rgba(1, 18, 43, 0.49), rgba(3, 46, 119, 0.924));
    position: absolute !important;
    content:'';
    height: 100%;
    width: 100%;
    z-index: -1;
    bottom:0;
    right:0;
    // animation: selection-deposit 1000ms ease 0ms infinite alternate; 
    border-radius:3px;
    opacity: 0.3;
    transition: all 200ms ease;
  } 


  .submit-jimf:hover::before{
    opacity: 1;
    transition: all 200ms ease;
  } 


  .Mensaje {
    display: grid;
    position: absolute;
    top:0;
    right:0;
    height: 100%;
    width:100%;
    background:#000;
    z-index:333;
    align-items: center;
    justify-content: center;
    animation: entrando-jimf 1200ms ease 0ms 1 normal;
    color: #fff;    
    text-align: center;
    img {
          height: 50px !important;
    }


    h2 {
      color: #fff;  
      font-size: 20px;  
      margin:30px 0;
      border-bottom:1px solid rgba(255, 255, 255, 0.249);
      padding: 20px 0;
    }

    button {
      background: #022967;
      color: #fff;
      border-style: none !important;
      padding: 15px 40px;
      border-radius: 6px;
    }

  }


  .Mensaje::before {
    background: linear-gradient( to bottom, rgba(1, 18, 43, 0.49), rgba(3, 46, 119, 0.924));
    position: absolute !important;
    content:'';
    height: 100%;
    width: 100%;
    z-index: -1;
    bottom:0;
    right:0;
     animation: selection-deposit 4000ms ease 0ms infinite alternate; 
    border-radius:3px;
    opacity: 0.3;
    transition: all 200ms ease;
  } 



  //////// aqui vamos a colocar los Spinners

  .line-Spinner-son {

    position: absolute;
    background: linear-gradient( to right, rgba(14, 73, 202, 0.241), #32e97b50 );
    border-bottom:1px solid #32e97b;
    height: 25px;
    width: 0%;
    animation: line-spinner 1600ms linear infinite normal;  
    top:0;
    left: 0;
    width: 100%;
    border-radius: 2px;
  } 


  .line-Spinner {
    height: 30px;

    
    display: grid;
    position: relative;
    
    grid-template-columns: 100%;



    h3 {
      color: #fff;
      margin: 0;
      font-size: 15px;
      z-index: 888;
    }
  } 
   
  .padd-mad {
    padding-left: 2px !important;
    padding-right: 2px !important;
    padding-top: 4px !important;
    height: 7%;
  }


  @keyframes line-spinner  {


from {
  width: 0%;
}

to {
  width: 100%;
}

  } 


  .color-bust-amount {
    color: #8dbeff !important;  
    text-shadow: 3px -3px 0px #000;
    
    border-radius: 1px !important;
    background:rgba(0, 0, 0, 0.294) !important;
    border-bottom:1px solid #4490f4a3 !important;
  } 


  .green-panther {
    color: rgb(78, 254, 190) !important;
    
  }


  
  .red-panther {
    color: rgb(254, 78, 140) !important;
    
  }

  
  .war-panther {
    color: rgb(255, 194, 97) !important;
    
  } 

  .busqueda-filter-box {
    display: grid;
    grid-template-columns: 60% 40%;
    padding-bottom: 10px ;
    position: relative;
     input  {
       border-style:none !important;
       background: linear-gradient(to right, rgba(0, 0, 0, 0.222),rgba(0, 11, 35, 0.715));
       padding: 10px 20px;
       border:1px solid rgba(255, 255, 255, 0.241) !important;
       color: #fff;
       width: 100%;
       transition: all 150ms ease;
     } 

     input:focus {
       border: 1px solid #195dca !important;
       transition: all 150ms ease;
     }
  } 


  .busqueda-filter-box-white {
    display: grid;
    grid-template-columns: 60% 40%;
    padding-bottom: 10px ;
    position: relative;
     input  {
       border-style:none !important;
       background: #fff;
       padding: 10px 20px;
       border:1px solid rgba(255, 255, 255, 0.241) !important;
       color: rgb(23, 42, 148);
       width: 100%;
       transition: all 150ms ease;
     } 

     input:focus {
       border: 1px solid #195dca !important;
       transition: all 150ms ease;
     }
  } 





  //////

  .pagination-components-white {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    position: relative;


    button {
      border-style: none;
      background: #022967;
      color: #fff;
      outline:none !important;
    }

    button:hover {
      border-style: none;
      background: #195dca;
      color: #fff;
      outline:none !important;
    } 

    p {
      margin: 0;
      text-align: center;
      color:rgb(23, 42, 148) !important;
    }

    input {
      padding-left: 2px !important;
      padding-right: 2px !important;
      //  border-bottom: none !important;
      //  border-top: none !important;
      //  border-left: none !important;
      text-align: center;

    }


    input:focus {
      background: rgb(255, 255, 255) !important;
      color: rgb(23, 42, 148) !important;
    }


    span {
      display: grid;
      grid-template-columns: 45% 55%;
      align-items: center;
      background: rgba(255, 255, 255, 0.266);
      justify-content: center;
      // border: 1px solid rgba(255, 255, 255, 0.186);
      

    }

    strong {
      color: rgb(23, 42, 148);
    }

  }  




  .pagination-components {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    position: relative;


    button {
      border-style: none;
      background: #022967;
      color: #fff;
      outline:none !important;
    }

    button:hover {
      border-style: none;
      background: #195dca;
      color: #fff;
      outline:none !important;
    } 

    p {
      margin: 0;
      text-align: center;
      color: #4490f4 !important;
    }

    input {
      padding-left: 2px !important;
      padding-right: 2px !important;
      //  border-bottom: none !important;
      //  border-top: none !important;
      //  border-left: none !important;
      text-align: center;

    }


    input:focus {
      background: #000 !important;
      color: #ffff !important;
    }


    span {
      display: grid;
      grid-template-columns: 45% 55%;
      align-items: center;
      background: rgba(37, 37, 40, 0.266);
      justify-content: center;
      // border: 1px solid rgba(255, 255, 255, 0.186);
      

    }

    strong {
      color: #fff;
    }

  } 



  @media screen and (min-width:0px)  and (max-width:500px){




    .busqueda-filter-box {
      display: grid;
      grid-template-columns: 40% 60%;
    }

    .busqueda-filter-box-white {
      display: grid;
      grid-template-columns: 40% 60%;
    }

    .ultra-depositos-todos {

      .fix-responsive-size {
        display: grid;
        align-items: center;
        
          transform: scale(0.90);
          font-size: 10px;
  
      
      } 


    }

    .ultra-depositos-todos-white {

      .fix-responsive-size {
        display: grid;
        align-items: center;
        
          transform: scale(0.90);
          font-size: 10px;
  
      
      } 


    }

    .ultra-depositos-todos-head {

      .fix-responsive-size {
        display: grid;
        align-items: center;
        
          transform: scale(0.90);
          font-size: 10px;
  
        
      } 

    }


    .ultra-depositos-todos-head-white {

      .fix-responsive-size {
        display: grid;
        align-items: center;
        
          transform: scale(0.90);
          font-size: 10px;
  
        
      } 

    }


   }





  @media screen and (min-width:0px)  and (max-width:992px){

    .ultra-deposito-jimf {
      column-gap: 6px !important;
      font-size: 10px;
    }

    .ultra-deposito-jimfwhite {
      column-gap: 6px !important;
      font-size: 10px;
    }


    .body-deposito {
      padding: 10px;
    }



    .ultra-depositos-todos {
      grid-template-columns: 25% 25% 25% 15% !important;
      height: 60px;


      
    }

    .ultra-depositos-todos-white {
      grid-template-columns: 25% 25% 25% 15% !important;
      height: 60px;


      
    }


    .ultra-depositos-todos-head {
      grid-template-columns: 25% 25% 25% 15% !important;
    }

    .ultra-depositos-todos-head-white {
      grid-template-columns: 25% 25% 25% 15% !important;
    }


    .hide-responsive {
      display: none !important;
    }


    
  .fix-responsive-size {
    width:100px !important; 
    position: relative;
    line-height: 11px;
    strong {
      white-space:normal !important;
    }

    }

  
    .header-deposito {
      grid-template-columns: 50% 50%;
    }


  } 


@media screen and (min-width:0px) and (max-width:500px) {
  .nav-bar-fixed::after {
    animation: none !important;
  }


  .nav-bar-fixed::before {
    animation: none !important;
  }
} 


.desktop-responsive-jimf {
  display: none;
}
   
.mobile-responsive-jimf {
  display: none;
}

@media screen and (min-width:0px) and (max-width:600px) {

    .mobile-responsive-jimf {
      display: grid;
    }

} 


@media screen and (min-width:601px) and (max-width:8000px) {
    
  .desktop-responsive-jimf  {
    display: grid;
  }

} 


.box-cuentas-jimf {
  display: block;
  // border:1px dashed rgb(0, 153, 255);
  background: linear-gradient( to top, #708afd42, rgba(5, 11, 29, 0.553));
  
  


  .header-box-cuentas-jimf {
    display: grid;
    width: 100%;
    padding: 10px;
    grid-template-columns: 80% 20%;
    justify-content: space-between;
    border-bottom: 1px solid #195dca;
    max-height: 50px;
    align-items: center;

    button {
      display: grid;
      justify-content: center;
      align-items: center;
      border-style: none;
      background: rgba(0, 0, 0, 0.323);
      color:#4490f4;
      border:1px solid #4490f4;
      border-radius: 3px;
      transition: all 200ms ease;
    }

    button:hover {
      border:1px solid #fff;
      color: #fff;
    }
  }

  .body-box-cuentas-jimf {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  }
  
} 


.cuentas-jimf {
  border: 1px solid #575757;
  animation: entrando-jimf 250ms ease 0ms 1 normal;
  display: grid;
  grid-template-columns: 100%;
} 

.cuentas-inter-jimf {
  display: grid;
} 


.cuentas-inter-jimf {
  margin: 10px 10px;
  display: grid;
  height: 100px;
  grid-template-columns: 50% 50%;
  align-items: center;
  border:1px solid #ffffff3b;
  border-radius: 2px;
  background: linear-gradient( to right bottom,#708afd22,#d2efff4e, #708afd22);
  padding: 10px;
  box-shadow: 0px 10px 14px rgba(1, 6, 10, 0.25);
  

  p{
    font-size: 10px;
    margin: 0 !important;
    color:#a7cdff;
    text-shadow: 0px 0px 4px #000;
  }

  small {
    color:#a7cdff;
    margin: 0 !important;
    text-shadow: 0px 0px 4px #000;
  }


}


.cuentas-card-jimf {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: center;
  
  height: 40px;
}

