


.datos-jimf {
    display: grid;
    height: 100%;
    width: 100%;
    position: relative;
    background-size: cover !important;
    background-repeat: repeat !important;
    z-index: 0;
    position: relative !important;
    
    border: 1px solid #d5c9a9 !important;
    padding: 40px;
    overflow: hidden !important;

    @media screen and (min-width:0px) and (max-width:992px) {
        height: 100%;
    
        padding: 10px;
        
       }
        

}  

 .box-data-complete {
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    column-gap: 10px;
    row-gap: 10px;
    width: 100%;
    justify-content: center;

    @media screen and (min-width:0px) and (max-width:992px) {
        grid-template-columns: 100%;
       }
    

    input {
        border-style: none !important;
        background: rgba(15, 17, 30, 0.423) !important;
        border-radius: 2px 2px 0px 0px !important;
        color: #ffd !important;
        border-bottom:1px solid #4490f4 !important;
        padding: 10px 10px;
    }

}

.input-size-jimf {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    
}



.form-data-complete{
    display: grid;
    height: 100%;
    width: 100%;
    position: relative;
    background-size: cover !important;
    background-repeat: repeat !important;
    z-index: 0;
    position: relative !important;
    justify-content: center;
    
    border: 1px solid #d5c9a9 !important;
    
    overflow: hidden !important;

    @media screen and (min-width:0px) and (max-width:992px) {
        height: 100%;
    
        padding: 10px;
        
       }
        

} 


.Usuario-fill {
    color: #000;
}


////////// esta clase es temporal ///// 


.header-deposito {
    height: 100px;
}


.header-deposito-white {
    height: 100px;
} 


.completar-datos {
    
    position: relative;
    margin: auto 0px;
    

    button {
        font-size: 11px !important;
    }

} 

.box-panther7 {
    display: grid;
    margin: 20px auto;
} 

.tittle-box {
    margin-top: 50px;
    


    h3 {
        color: rgba(55, 132, 255, 0.864);
        text-align: center;
        font-size: 15px;

        strong {
            color: #fff;
        }
        
    }
    
    h2  {
        display: grid;
        margin:0;
        width: 100%;
        grid-template-columns: 100%;
        text-align: center;
        
        padding: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #4490f4;
        margin-bottom: 10px;


    }


}

.form-data-complete form {
    
    width: 100%;
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.568);
    animation: entrada 400ms ease 0ms 1 normal;
    transition: all 200ms ease;
} 


@keyframes entrada {

    from {
        opacity: 0;
        transform: scale(0.97);
    }
    to {
        opacity: 1;
        margin-bottom:0px;
        transform: scale(1);
    }
    
}



.back-btn-jimf {
    display: grid;
    position: absolute;
    top:3px;
    right: 3px;
    color: #fff;
    font-size: 30px;
    
    button {
        background: #2885ff;
        height: 50px;
        width: 50px;
        display: grid;
        text-align: center;
        align-items: center;
        border-radius: 1px;
        color: #fff;
        transition: 100ms ease;
        cursor: pointer;
        border-style: none;
        
        
    }

    button:hover {
        background: rgb(61, 96, 255);
        transition: 100ms ease;
        cursor: pointer;
    }

} 


.input-simples-jimf-password {
    display: grid;
    grid-template-columns: 100%;
    
    margin: 10px auto;



    input {
        border-style:none !important;
        width:100% !important;
        
        background: rgba(0, 0, 1, 0.261);
        color: #fff;
        padding: 10px;
        border-bottom: 1px solid #2885ff !important;

    }

    label {
        padding-left: 10px;
        color: #708afd !important;
    }

    
    input:focus {
        border-style:none !important;
        width:100% !important;
        
        background: rgba(0, 0, 1, 0.261) !important;
        color: #fff;
        padding: 10px;
        border-bottom: 1px solid #2885ff !important;

    }
} 

.figure-power {
    
    display: grid;
    height: 100%;

    div {
        border: 1px solid #000;
        background: #245bb964;
        padding: 40px;
        svg {
            width:100% ;
            height:100% ;
        }

    }
    
}


.form-fix {
    grid-template-columns: 65% 35% !important;
    display: grid;
}