
.grid-container-tabla-proyecto {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:repeat(8,1fr);
    line-height: 8px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong {
        font-size: 15px;
        
    }
    
  
  
  
  
    @media screen and(min-width:0px) and (max-width:500px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 15px; 
      row-gap: 15px; 
      height: 220px;
      padding-right: 10px;
      padding-left: 10px;
  
    } 
  
    
    @media screen and(min-width:500px) and (max-width:768px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 5px; 
      row-gap: 5px; 
      height: 220px;
      padding-right: 0px;
      padding-left: 0px;
  
    }
  
  
  
  
    
    div {
      border:1px solid #060e1d87;
    //   background:linear-gradient(to bottom left, #00113e, #001241d1);
      
    background-position: 50% 60% !important;
    background-size:150% ;
    background-repeat:no-repeat ;
  box-shadow: 0px 0px 6px #000 !important;
  border: 1px solid #0066ff91 !important;
  line-height: 19px !important;
  transition: all 500ms ease;
  border-radius: 8px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
      border-radius: 3px;
      padding:  1px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 500ms ease;
      align-items: center;
      flex-wrap: wrap;
      border-radius: 20px;
    
      align-content: center;
      box-shadow: 0px 0px 4px #000921;
      margin: 3px;
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 



  .nav-bar-fixed{
    background: linear-gradient(to left, #0034416c, #067676ca), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:no-repeat ;
      filter: saturate(70%);
      
    position: fixed;
    border:1px solid #ffffff1c;
  
    margin-left: 5px;
    margin-right: 3px;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
    display: flex;
    overflow: hidden;

    
  }

  .nav-bar-fixed::before {
    height: 100%;
    width: 100%;
    bottom: 0px;
    left: 0px;
    content: "";
    position: absolute;
    z-index: -1;
    backdrop-filter: blur(25px);
    background: #05050593 !important;
    // animation: time-nav 60.8s ease infinite alternate;
    // box-shadow: 0px 9px 0px rgba(132, 99, 1, 0.304) 

  } 

  .nav-bar-fixed::after {
    height: 1px;
    width: 0%;
    top: 3px;
    right: 0px;
    content: "";
    position: absolute;
    z-index: 2;
    
    background: #746f34 !important;
    animation: time-nav-rev 60.8s infinite alternate;
    box-shadow: 0px -10px 0px rgb(255, 255, 255)

  }


  @keyframes time-nav{
    from{
      width: 0%;
    }

    top{
      width: 100%;
    }
  }


  @keyframes time-nav-rev{
    from{
      width: 30%;
    }

    top{
      width: 0%;
    }
  }

  .grid-container-tabla-proyecto-body {
    display: grid;
    grid-auto-rows: minmax(30px, auto);
    grid-template-columns:repeat(8,1fr);
    line-height: 12px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong {
        font-size: 15px;
        color: #fff ;
        
    }
    
  
  
  
  
    @media screen and(min-width:0px) and (max-width:500px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 15px; 
      row-gap: 15px; 
      height: 220px;
      padding-right: 10px;
      padding-left: 10px;
  
    } 
  
    
    @media screen and(min-width:500px) and (max-width:768px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 5px; 
      row-gap: 5px; 
      height: 220px;
      padding-right: 0px;
      padding-left: 0px;
  
    }
  
  
  
  
    
    div {
      border:1px solid #060e1d87;
    //   background:linear-gradient(to bottom left, #00113e, #001241d1);
      
    background-position: 50% 60% !important;
    background-size:150% ;
    background-repeat:no-repeat ;
  box-shadow: 0px 0px 3px rgb(17, 17, 17) !important;
  border: 1px solid #ffffff17 !important;
  
  transition: all 500ms ease;
  
  border-radius: 0px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
      
      padding:  1px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 500ms ease;
      align-items: center;
      flex-wrap: wrap;
      
    
      align-content: center;
      box-shadow: 0px 0px 4px #000921;
      margin: 2px;
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 





  .first-grid {
    grid-template-columns:repeat(5,1fr);
    background-size:100% !important;
    background-repeat:repeat !important;
  } 


  .class-grid-color {
    
    padding: 25px 4px 2px 4px !important;
      transition: all 500ms ease;
      background: none;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 0px 0px 12px 0px ;


      strong {
        font-size: 13px;
        font-weight: 900 !important;
    }



  }


  
  

@keyframes animationff {
    0% {
        background-position: 50% 60% !important;
        
        
        
    }
    100% {
        
        background-position: 50% 100% !important;
    }
  
  } 

  
  
  .class-grid-color-body {
    border:1px solid #a9a9a95e;
      padding: 5px 4px 5px 4px !important;
      transition: all 500ms ease;
      background: #0305465e;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 3px 0px 3px 0px ;

      .text-comprar {


        font-size: 30px;
        display: none;
        animation:animation-open 600ms ease-in-out 0ms 1 normal;
        transition: all 500ms ease;
        span{
            font-weight: 900;
        } 

        i{
            display: flex;
        }
    } 

    strong {
        font-size: 14px;
        font-weight: 400 !important;
    }

    
   
      
  }


  
  .class-grid-color-body:hover {
    
    padding: 20px 4px 25px 4px !important;
    
    background-position: 50% 100%;
    background-size:100% ;
    background-repeat:repeat ;
    transition: all 500ms ease;
    border-radius: 3px;
    box-shadow: 0px 0px 4px #000;
    cursor:pointer; 
    animation: animationff 100ms infinite alternate;
    position: relative;
    font-size: 10px;
    transition: all 500ms ease;
    color: #fff99e;
    
    text-shadow: 0px 0px 1px rgb(230, 159, 6);
    cursor: pointer;
    background: #0f0b0185;
    overflow:visible; 

    .text-comprar{
        display: block;
        animation:animation-open 600ms ease-in-out 0ms 1 normal;
        transition: all 500ms ease;

    }
    
}


.class-grid-color-body:nth-child(even) {
    background: #000f135e;
    border:1px solid rgba(255, 255, 255, 0.152);
}

.class-grid-color-body:nth-child(even):hover {
    background: #0f0b0185;
    border:1px solid rgba(255, 255, 255, 0.152);
} 

.card-smooth{
    transition: all 500ms ease;
}




////////////////////////////// mercado primariop grid sistem 
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////



.grid-container-tabla-proyecto-primario {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:repeat(8,1fr);
    line-height: 8px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong {
        

        @media screen and(min-width:992px) and (max-width:1350px) {
          font-size: 8px ;
      
        } 
  
          font-size: 15px;
          
          
        
    }
    
  
  
  
  
    @media screen and(min-width:0px) and (max-width:500px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 15px; 
      row-gap: 15px; 
      height: 220px;
      padding-right: 10px;
      padding-left: 10px;
  
    } 
  
    
    @media screen and(min-width:500px) and (max-width:768px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 5px; 
      row-gap: 5px; 
      height: 220px;
      padding-right: 0px;
      padding-left: 0px;
  
    }
  
  
  
  
    
    div {
      border:1px solid #060e1d87;
    //   background:linear-gradient(to bottom left, #00113e, #001241d1);
      
    background-position: 50% 60% !important;
    background-size:150% ;
    background-repeat:no-repeat ;
  box-shadow: 0px 0px 6px #000 !important;
  border: 1px solid #0066ff91 !important;
  line-height: 19px !important;
  transition: all 500ms ease;
  
  border-radius: 8px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
      border-radius: 3px;
      padding:  1px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 500ms ease;
      align-items: center;
      flex-wrap: wrap;
      border-radius: 20px;
    
      align-content: center;
      box-shadow: 0px 0px 4px #000921;
      margin: 3px;
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 

/////////////////////////////// lista de mis ivnaersiones

  .grid-container-tabla-inversiones {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:repeat(9,1fr);
    line-height: 8px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong {
        

        @media screen and(min-width:992px) and (max-width:1350px) {
          font-size: 8px 
      
        } 
  
          font-size: 15px 
          
          
        
    }
    
  
  
  
  
    @media screen and(min-width:0px) and (max-width:500px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 15px; 
      row-gap: 15px; 
      height: 220px;
      padding-right: 10px;
      padding-left: 10px;
  
    } 
  
    
    @media screen and(min-width:500px) and (max-width:768px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 5px; 
      row-gap: 5px; 
      height: 220px;
      padding-right: 0px;
      padding-left: 0px;
  
    }
  
  
  
  
    
    

    div {
      border:1px solid #060e1d87;
    //   background:linear-gradient(to bottom left, #00113e, #001241d1);
      
    background-position: 50% 60% !important;
    background-size:150% ;
    background-repeat:no-repeat ;
        box-shadow: 0px 0px 6px #000 !important;
        border: 1px solid #0066ff91 !important;
        line-height: 19px !important;
        transition: all 500ms ease;
        
  border-radius: px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
      
      padding:  1px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 500ms ease;
      align-items: center;
      flex-wrap: wrap;
    
      
    
      align-content: center;
      box-shadow: 0px 0px 4px #000921;
      margin: 0px;
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 


  .grid-container-tabla-proyecto-body-primario {
    display: grid;
    grid-auto-rows: minmax(30px, auto);
    grid-template-columns:repeat(8,1fr);
    line-height: 12px;
    
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong {
        font-size: 15px;
        
        
        
    }
    
  
  
  
  
    @media screen and(min-width:0px) and (max-width:500px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 15px; 
      row-gap: 15px; 
      height: 220px;
      padding-right: 10px;
      padding-left: 10px;
  
    } 
  
    
    @media screen and(min-width:500px) and (max-width:768px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 5px; 
      row-gap: 5px; 
      height: 220px;
      padding-right: 0px;
      padding-left: 0px;
  
    }
  
  
  
  
    
    div {
      border:1px solid #060e1d87;
    //   background:linear-gradient(to bottom left, #00113e, #001241d1);
      
    background-position: 50% 60% !important;
    background-size:150% ;
    background-repeat:no-repeat ;
  box-shadow: 0px 0px 3px rgb(17, 17, 17) !important;
  border: 1px solid #ffffff17 !important;
  
  transition: all 500ms ease;
  
  border-radius: 0px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
      
      padding:  1px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 500ms ease;
      align-items: center;
      flex-wrap: wrap;
      
    
      align-content: center;
      box-shadow: 0px 0px 4px #000921;
      margin: 2px;
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 

  .grid-6-fr {
    grid-template-columns:repeat(6,1fr);
  }



  .grid-5-fr {
    grid-template-columns: repeat(5,1fr);
  }

  .grid-8-fr {
    grid-template-columns:repeat(8,1fr);
  }


  .grid-container-label-proyecto {
    display: grid;
    grid-auto-rows: minmax(30px, auto);
    column-gap: 3px;
    
    line-height: 12px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong { 

      @media screen and(min-width:992px) and (max-width:1350px) {
        font-size: 10px !important;
    
      } 

        font-size: 13px !important;
        color:#000;
        font-weight: 600 !important;
        
        
    }
    
  
  
    
    div {
    border: none !important;
    
    background:linear-gradient(to bottom, rgb(204, 223, 255), rgb(233, 238, 242));
    border-left: 1px solid #003185 !important;
    
    box-shadow: 0px 0px 3px rgb(17, 17, 17) !important;
    
    transition: all 200ms ease;
    border-radius: 0px !important;
    animation: animation-on-hover-bg 28.8s infinite alternate;  
    padding:  1%;
    
    display: flex;
    justify-content: flex-start;
    transition: all 200ms ease;
      
      flex-wrap: wrap;
      
      text-align: left !important;
      
      
      margin: 0px;
      
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 









  .first-grid-primario {
    grid-template-columns:repeat(6,1fr);
    background-size:100% !important;
    background-repeat:repeat !important;
  } 


  .class-grid-color-primario {
    
    padding: 15px 4px 15px 4px !important;
      transition: all 200ms ease;
      background: linear-gradient(to left, #02040bcf, #092b69bf, #04111fcf), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 0px 0px 12px 0px ;
      box-shadow: 0px 6px 8px #000;
      


      strong {
        font-size: 13px;
        font-weight: 900 !important;
    }



  }


  
  

@keyframes animationff {
    0% {
        background-position: 50% 60% !important;
        
        
        
    }
    100% {
        
        background-position: 50% 100% !important;
    }
  
  } 

  
  
  .class-grid-color-body-primario {
    border:1px solid #a9a9a95e;
      padding: 5px 4px 5px 4px !important;
      transition: all 200ms ease;
      background: #000000d2 !important; 
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      transform:scale(0.88);
      margin:0px;
      color:rgb(234, 234, 234);
      border-left:10px solid #5164f9;
      .text-comprar-primario {


        font-size: 18px; 
        margin-left:auto;
        margin-right:auto;
        width: 600px !important;
        padding: 20px;
        display: block;
        animation:animation-open 600ms ease-in-out 0ms 1 normal;
        transition: all 200ms ease;
        span{
            font-weight: 900;
        } 

        i{
            display: flex;
        }
    } 

    strong {
        font-size: 14px;
        font-weight: 400 ;
        text-shadow: 0px 0px 12px #0000; 

        @media screen and(min-width:992px) and (max-width:1350px) {
          font-size: 11px !important;
      
        } 


    }

    
   
      
  }


  
  .class-grid-color-body-primario:hover {
    
    padding: 20px 4px 25px 4px !important;
    
    background-position: 50% 100%;
    background-size:100% ;
    background-repeat:repeat ;
    transition: all 200ms ease;
    border-radius: 3px;
    transform:scale(0.85);
    background : rgb(11, 51, 97) !important;
    
    cursor:pointer; 
    animation: animationff 100ms infinite alternate;
    position: relative;
    font-size: 10px;
    transition: all 200ms ease;
    color: #fff99e;
    border-left:10px solid rgb(230, 159, 6) !important; 
    
    text-shadow: 0px 0px 1px rgb(230, 159, 6);
    
    background: #0f0b0185;
    overflow:visible; 

    .text-comprar-primario{
        display: block;
        animation:animation-open 600ms ease-in-out 0ms 1 normal;
        transition: all 200ms ease;

    }
    
}


.class-grid-color-body-primario:nth-child(even) {
  background: linear-gradient(to left, #02040bcf, #092b69bf, #04111fcf), url(./Panther-pattern-7.png) ;
    border-left:10px solid #5164f9 !important; 
    border:1px solid rgba(255, 255, 255, 0.152);
    box-shadow: 0px 0px 10px #000;
}

.class-grid-color-body-primario:nth-child(even):hover {
    background: #0f0b0185;
    border:1px solid rgba(255, 255, 255, 0.152);
    border-left:10px solid rgb(230, 159, 6) !important; 
} 

.card-smooth-primario{
    transition: all 500ms ease;
}



///////////////////////////////inversiones
///////////////////////////////inversiones
///////////////////////////////inversiones
///////////////////////////////inversiones
///////////////////////////////inversiones
///////////////////////////////inversiones




.grid-container-tabla-inversiones {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:repeat(9,1fr);
    line-height: 8px;
    
    
    box-shadow: 0px 0px 4px #000;
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong {
        font-size: 13px !important;
        line-height: 12px !important;
        text-align: left;

        i {
            color: rgb(109, 254, 143);
        }
        

        // @media screen and(min-width:768px) and (max-width:992px) {
  
        //     font-size: 10px !important;
        //     line-height: 10px !important;
        
        //   } 
    }
    
  
  
  
  
    @media screen and(min-width:0px) and (max-width:500px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 15px; 
      row-gap: 15px; 
      height: 220px;
      padding-right: 10px;
      padding-left: 10px;
  
    } 
  
    
    @media screen and(min-width:500px) and (max-width:768px) {
  
      grid-template-columns:repeat(2,1fr);
      grid-auto-rows: minmax(50px, auto);
      column-gap: 5px; 
      row-gap: 5px; 
      height: 220px;
      padding-right: 0px;
      padding-left: 0px;
  
    }
  
  
  
  
    
    div {
      border-bottom:1px solid #0066ff91;
    //   background:linear-gradient(to bottom left, #00113e, #001241d1);
    
    background-position: 50% 60% !important;
    background-size:150% ;
    background-repeat:no-repeat ;
  
  border-top: 1px solid #0066ff91 !important;
  line-height: 19px !important;
  transition: all 500ms ease;
  
  border-radius: 0px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
      
      padding:  1px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 500ms ease;
      align-items: center;
      flex-wrap: wrap;
      
    
      align-content: center;
      
      margin: 0px;
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 
  


  .grid-container-tabla-proyecto-body-inversiones {
    display: grid;
    grid-auto-rows: minmax(30px, auto);
    grid-template-columns:repeat(8,1fr);
    line-height: 12px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
   
   

    strong { 

      


      @media screen and(min-width:992px) and (max-width:1350px) {
        font-size: 8px !important;
    
      } 

        font-size: 15px !important;
        
        
        
    }
    
  
  
  
  

  
  
    
    div {
      
    //   background:linear-gradient(to bottom left, #00113e, #001241d1);
      
    background-position: 50% 60% !important;
    background-size:150% ;
    background-repeat:no-repeat ;
  box-shadow: 0px 0px 3px rgb(17, 17, 17) !important;
  border: 1px solid #ffffff17 !important;
  
  transition: all 500ms ease;
  
  border-radius: 0px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
      
      padding:  1px;
      align-items: center;
      display: flex;
      justify-content: center;
      transition: all 500ms ease;
      align-items: center;
      flex-wrap: wrap;
      
    
      align-content: center;
      
      margin: 2px;
      
  
      
  
    } 
  
  
  
    span{
      font-size: 10px;
      color: #fff;
      width: 100%;
    }
  
  p {
    font-size: 20px;
    margin: 0px !important;
    width: 100%;
    line-height: 19px !important;
    transition: all 500ms ease;
    
  
  }
  p:hover {
    font-size: 20px;
  }
  
  } 




  .grid-body-inversiones {
    display: grid;
    grid-auto-rows: minmax(10px, auto) !important;
    grid-template-columns:repeat(9,1fr);
    line-height: 8px;
    
    
    box-shadow: 0px 0px 4px #000;
    padding: 1px;
    text-align: center;
    width: 100%; 

    div {
        height: 45px; 

        button {
            margin:0px;
        }
    }
  } 



  .first-grid-inversiones {
    grid-template-columns:repeat(6,1fr);
    background-size:100% !important;
    background-repeat:repeat !important;
  } 


  .class-grid-color-inversiones {
    border:10px solid rgb(6, 74, 163);
    padding: 15px 4px 15px 4px !important;
      transition: all 500ms ease;
      background: linear-gradient(to left, #02040bcf, #092b69bf, #04111fcf), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 0px 0px 12px 0px ;
      
      


      strong {
        font-size: 13px;
        font-weight: 900 !important;
    }



  }


  
  

@keyframes animationff {
    0% {
        background-position: 50% 60% !important;
        
        
        
    }
    100% {
        
        background-position: 50% 100% !important;
    }
  
  } 

  
  
  .class-grid-color-body-inversiones {
    border:1px solid #a9a9a95e;
      padding: 5px 4px 5px 4px !important;
      transition: all 500ms ease;
      background: #0305465e;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 10px 0px 10px 0px ;
      border-left:10px solid #5164f9;
      .text-comprar-inversiones {


        font-size: 40px;
        display: none;
        animation:animation-open 600ms ease-in-out 0ms 1 normal;
        transition: all 500ms ease;
        span{
            font-weight: 900;
        } 

        i{
            display: flex;
        }
    } 

    strong {
        font-size: 14px;
        font-weight: 400 !important;
       
    }

    div {
        box-shadow: none !important;
        border-left:none !important;
        border-right:none !important;
        
    }

    .body-inversiones-shadow   {
        box-shadow: 0px 0px 10px #000 !important;
    }
   
      
  } 

  .header-inversiones-grid  {
   
   div {
    
    box-shadow: none !important;
        border-left:none !important;
      border-right:none!important;
   }

   strong {
    padding-top: 10px;
    display: flex !important;
    flex-direction: column !important;
    padding-bottom: 20px;
    
}
  }

  .footer-inversiones-grid {
      background:linear-gradient(to right, #0154d2f2, #0453dcf9);
      
      border-left:none;
      border-right:none;
      div {
          font-size: 20px !important;
        box-shadow: none !important;
        border-left:none !important;
      border-right:none !important;
      }
  }

  


  
  .class-grid-color-body-inversiones:hover {
    
    padding: 20px 4px 25px 4px !important;
    
    background-position: 50% 100%;
    background-size:100% ;
    background-repeat:repeat ;
    transition: all 200ms ease;
    border-radius: 3px;
    box-shadow: 0px 0px 4px #000;
    cursor:pointer; 
    animation: animationff 100ms infinite alternate;
    position: relative;
    font-size: 10px;
    transition: all 200ms ease;
    color: #fff99e;
    border-left:10px solid rgb(230, 159, 6) !important; 
    
    text-shadow: 0px 0px 1px rgb(230, 159, 6);
    cursor: pointer;
    background: #0f0b0185;
    overflow:visible; 

    .text-comprar-primario{
        display: block;
        animation:animation-open 600ms ease-in-out 0ms 1 normal;
        transition: all 200ms ease;

    }
    
}


.class-grid-color-body-inversiones:nth-child(even) {
    background: #00031d;
    border-left:10px solid #5164f9 !important; 
    border:1px solid rgba(255, 255, 255, 0.152);
    box-shadow: 0px 0px 10px #000;
}

.class-grid-color-body-inversiones:nth-child(even):hover {
    background: #0f0b0185;
    border:1px solid rgba(255, 255, 255, 0.152);
    border-left:10px solid rgb(230, 159, 6) !important; 
} 

.card-smooth-inversiones{
    transition: all 500ms ease;
}



/////////////////////////////////////////////////////////
///////////////// depositos ////////////////////////
///////////// depositos ///////////@at-root
/////////////////////////////////////////////////////////
///////////////// depositos ////////////////////////
/////////////////////////////////////////////////////////
///////////////// depositos ////////////////////////
/////////////////////////////////////////////////////////
///////////////// depositos ////////////////////////




.grid-container-tabla-depositos {
    display: grid;
    grid-auto-rows: minmax(20px, auto);
    grid-template-columns:repeat(6,1fr);
    line-height: 8px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
    
    div{
        
    align-items: center;
    display: flex;
}
    
    strong {
        
        padding: 0px 10px 0px 15px;
        flex-direction: column;
        

    }

} 

.depositos-box-grid{
    
    border:1px solid rgb(6, 74, 163);
    padding: 5px 4px 5px 4px !important;
      transition: all 500ms ease;
      background: linear-gradient(to left, #033480eb, #061f4bbf, #002348cf), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 0px 0px 1px 0px ;
      box-shadow: 0px 6px 8px #000;
      


      strong {
        font-size: 13px;
        line-height: 13px;
        font-weight: 900 !important;
        color:#97b1ff;


        @media screen and(min-width:0px) and (max-width:500px) {
  
            font-size: 10px;
          } 
    }
}

.depositos-box-grid-body{
    
    
    padding: 0px  !important;
      transition: all 500ms ease;
      background: linear-gradient(to left, #001560e9, #001234e4, #041324f7), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 5px 0px 5px 1px ;
      box-shadow: 0px 6px 8px #000;

      @media screen and(min-width:0px) and (max-width:500px) {
  
        margin: 2px 0px 2px 0px ;
      } 
      


      strong {
        font-size: 13px;
        line-height: 13px;
        font-weight: 300 !important;
        color:#ffffff;
        border-left:1px solid #97b1ff;
        height: 100%;

        @media screen and(min-width:0px) and (max-width:500px) {
  
            font-size: 10px;
          } 
        
    }

    div {
        grid-auto-rows: minmax(40px, auto);
    }

} 

.depositos-tabla-box  {
    padding: 0px;



    @media screen and(min-width:0px) and (max-width:500px) {
  
        font-size: 25px !important;
        padding: 20px;
      } 
} 

.card {

    @media screen and(min-width:500px) and (max-width:758px) {
  
        
        padding: 0px;
      } 
}

.registrar-deposito-lista {
    border:1px solid #2956ac;
    margin: 0px 5px 0px 5px; 
    h1 {
        font-weight: 600;
        text-align: center;
    }

    @media screen and(min-width:500px) and (max-width:758px) {
  
        
        margin: 0px 25px 0px 25; 
      } 
} 




/////////////////////////////////////////////////////////
///////////////// retiros ////////////////////////
/////////////////////////////////////////////////////////
///////////////// retiros ////////////////////////
/////////////////////////////////////////////////////////
///////////////// retiros ////////////////////////

/////////////////////////////////////////////////////////
///////////////// retiros ////////////////////////
/////////////////////////////////////////////////////////
///////////////// retiros ////////////////////////
/////////////////////////////////////////////////////////
///////////////// retiros ////////////////////////



.grid-container-tabla-retiros {
    display: grid;
    grid-auto-rows: minmax(20px, auto);
    grid-template-columns:repeat(7,1fr);
    line-height: 8px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
    
    div{
        
    align-items: center;
    display: flex;
}
    
    strong {
        padding: 0px 8px 0px 15px;
        flex-direction: column;
        

    }

} 

.retiros-box-grid{
    grid-template-columns:repeat(7,1fr);
    border:1px solid rgb(6, 74, 163);
    padding: 5px 4px 5px 4px !important;
      transition: all 500ms ease;
      background: linear-gradient(to left, #033480eb, #061f4bbf, #002348cf), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 0px 4px 1px 4px ;
      box-shadow: 0px 6px 8px #000;
      


      strong {
        font-size: 13px;
        line-height: 13px;
        font-weight: 900 !important;
        color:#97b1ff;
        flex-direction: column;


        @media screen and(min-width:0px) and (max-width:500px) {
  
            font-size: 10px;
          } 
    }
} 



.retiros-box-grid-body{
    
    
    padding: 0px  !important;
      transition: all 500ms ease;
      background: linear-gradient(to left, #001560e9, #001234e4, #041324f7), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 5px 0px 5px 1px ;
      box-shadow: 0px 6px 8px #000;

      @media screen and(min-width:0px) and (max-width:500px) {
  
        margin: 2px 0px 2px 0px ;
      } 
      


      strong {
        font-size: 13px;
        line-height: 13px;
        font-weight: 300 !important;
        color:#ffffff;
        border-left:1px solid #97b1ff;
        height: 100%;

        @media screen and(min-width:0px) and (max-width:500px) {
  
            font-size: 10px;
          } 
        
    }

    div {
        grid-auto-rows: minmax(40px, auto);
    }

} 

.retiros-tabla-box  {
    padding: 0px;



    @media screen and(min-width:0px) and (max-width:500px) {
  
        font-size: 25px !important;
        padding: 20px;
      } 
} 

.card {

    @media screen and(min-width:500px) and (max-width:758px) {
  
        
        padding: 0px;
      } 
}

.registrar-retiros-lista {
    border:1px solid #2956ac;
    margin: 0px 5px 0px 5px; 
    h1 {
        font-weight: 600;
        text-align: center;
    }

    @media screen and(min-width:500px) and (max-width:758px) {
  
        
        margin: 0px 25px 0px 25; 
      } 
} 


.flex-right-end  {
    align-items: flex-end !important;

    
} 


.animation-op {
    animation: op 900ms ease-in-out 3ms 1 normal;
   } 
  
   @keyframes op {
    from {
        margin-bottom: 10px;
        opacity:0.20;
        
        
    }
    to {
        margin-bottom: 0px;
        opacity:1;
        
    }
  
  }  



  ///////////////////////////// saldos

  .saldos-panther-final{
    
    // border:1px solid rgb(6, 74, 163);
   
      transition: all 500ms ease;
      background:linear-gradient(to bottom, #0000009e, #000b1fde), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:25% ;
      background-repeat:repeat ;
      border: 1px solid transparent !important;
      box-shadow: 0px 6px 8px #000;
      color: #00ff77 !important;
      


      
}  



////////////////////////////// ULTIMOS MOVIMIENTOS ///////////@at-root

.movimientos-box-grid{
    
    border:1px solid rgb(163, 137, 6);
    padding: 5px 4px 5px 4px !important;
      transition: all 500ms ease;
      background: linear-gradient(to left, #037c80eb, #064b35bf, #004838cf), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 0px 0px 1px 0px ;
      box-shadow: 0px 6px 8px #000;
      


      strong {
        font-size: 12px;
        line-height: 13px;
        font-weight: 900 !important;
        color:#97b1ff;


        @media screen and(min-width:0px) and (max-width:500px) {
  
            font-size: 10px;
          } 
    }
}


.grid-container-tabla-movimientos {
    display: grid;
    grid-auto-rows: minmax(20px, auto);
    grid-template-columns:repeat(5,1fr);
    line-height: 8px;
    transition:all 500ms ease;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
    
    div{
        
    align-items: center;
    display: flex;
    transition:all 500ms ease;
}
    
    strong {
        padding: 0px;
        transition:all 500ms ease;
        

    }

}  

.movimientos-box-grid-body{
    
    
    padding: 0px  !important;
      transition: all 500ms ease;
      background: linear-gradient(to left, #001560e9, #13af80e4, #041324f7), url(./Panther-pattern-7.png) !important;
      background-position: 50% 60% !important;
      background-size:100% ;
      background-repeat:repeat ;
      margin: 0px 0px 0px 0px ;
      transition:all 500ms ease;
      

      @media screen and(min-width:0px) and (max-width:500px) {
  
        margin: 2px 0px 2px 0px ;
      } 
      

      

      strong {
        font-size: 13px;
        line-height: 13px;
        font-weight: 300 !important;
        color:#ffffff;
        border-left:1px solid #0a2269;
        height: 100%;
        transition:all 500ms ease;



        @media screen and(min-width:0px) and (max-width:500px) {
  
            font-size: 10px;
          } 
        
    }

    div {
        grid-auto-rows: minmax(40px, auto);
    }

} 


.grid-container-tabla-movimientos {
    display: grid;
    grid-auto-rows: minmax(20px, auto);
    grid-template-columns:15% 15% 40% 15% 15%;
    line-height: 8px;
    
    
    
    padding: 1px;
    text-align: center;
    width: 100%;
    
    div{
        
    align-items: center;
    display: flex;
    transition:all 500ms ease;
}
    
    strong {
        padding: 0px 12px 0px 12px;
        font-size: 11px;
        transition:all 500ms ease;
        

    }  

   

    

   

} 

.movimientos-box-grid-body:hover {
    border:1px solid rgb(233, 200, 100);
    background: rgb(18, 13, 0) !important;
    
    strong{
        color: rgb(230, 214, 164);
        cursor: zoom-in;
        border-left:1px solid rgb(18, 13, 0) !important;
    }

    strong:hover {
        
        padding: 7px 5px 7px 5px;
        font-size: 16px;
        border: 10px solid #001fb728 !important;
        text-shadow:0px 0px 12px #000 ;
        background: rgba(13, 74, 255, 0.194);
        line-height:20px; 
        transition:all 500ms ease;
        

        

    }
}



.movimientos-box-grid-body:nth-child(even) {
    background: linear-gradient(to right, #0e3c32, #062457) !important;
    
} 

.movimientos-box-grid-body:nth-child(even):hover {
    border:1px solid rgb(233, 200, 100);
    background: rgb(18, 13, 0) !important;
    
} 

.align-items-flex-end {
    align-items: flex-end !important;
}

.align-items-flex-start {
    align-items: flex-start !important;
}

.align-items-flex-center {
    align-items: center !important;
} 

.justify-content-flex-start {
    justify-content: flex-start !important;
} 



////////////////////////////
///////////////// filtro de busqueda grid
////////////////////////////
///////////////// filtro de busqueda grid
////////////////////////////
///////////////// filtro de busqueda grid 






.grid-container-filtro-buscador {
  display: grid;
  grid-auto-rows: minmax(20px, auto);
  grid-template-columns:repeat(6,1fr);
  row-gap: 0px !important;
  line-height: 8px;
  
  
  
  padding: 1px;
  text-align: center;
  width: 100%;
  
  div{
      
  align-items: center;
  display: flex;
  transition:all 500ms ease;
}
  
  strong {
      padding: 10px 1px 10px 1px;
      font-size: 13px;
      transition:all 500ms ease;
      display: flex;
      align-items: center;
      flex-direction: column; 


      select {
        margin:0px;
        height: 45px;
      }
      

  }  

 
 
  @media screen and(min-width:0px) and (max-width:992px) { 

    display: grid;
  grid-auto-rows: minmax(20px, auto);
  grid-template-columns:repeat(1,1fr);
  justify-content: center;
  margin-top:15px !important;
  
    
 

  } 
  

 

}  


.font-weight-900 {
  font-weight: 900;
} 

.overflow-hidden {
  overflow: hidden !important;
} 


.card-proyectos-grid-bg {
  background:rgba(0, 0, 0, 0.248) !important;
  
}

.disponible-map {
  margin:0px 0px 0px 0px;
  background: linear-gradient( to right, rgba(3, 4, 1, 0.448), rgba(0, 0, 0, 0.112));
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 10px;
  width:100%;
  text-align: center;
  letter-spacing: 2px;
  font-size: 9px;
  position: relative;
  z-index: 999;
  margin-top: 30px !important; 
  
  h2 {
    margin-top: 0px !important; 
    
  }
} 



///// aqui vamos a colocar el grid de la paginacion
.grid-container-paginador {
  display: grid;
  grid-auto-rows: minmax(20px, auto);
  grid-template-columns:repeat(3,1fr);

  div {
    align-content: center;
    justify-content:center;
    display: grid;
    
  }
} 



///// aqui vamos a colocar el grid de la seleccion
////de proyectos 



.grid-app-bar {
  display:grid;
  grid-auto-rows: minmax(40px, auto);
  grid-template-columns:repeat(1,1fr);
  align-items: center;
  border-radius: 2px !important;
  width:100% !important;
  margin-bottom: 10px;
  margin-top: 10px;
  background:#05143ca7  !important;
  border-radius: 3px;
  transition: all 1000ms ease;
  border-radius: 40px;
  box-shadow: 0px 0px 17px #000;
  border:1px solid #e6c51e3f;
  padding:0.5rem 0px 0.5rem 0px  !important;
  

  h4 {
    display: none !important;
    
    
    
   }
  
  

  p {
    text-align: center;
    font-size: 25px;
    color:#37f2a1 ;
    font-weight: 500;
    justify-content: center; 
    line-height: 30px;
    span {
      text-align: center;
      font-size: 20px;
      color:#a2a2a2 ;
    }

    strong {
      font-size: 39px;
      font-weight: 300;
      color: #5bb3ff;
      text-shadow: 0px 0px 4px rgb(4, 54, 106);
      transition: all 500ms ease ;
    }
  }


  div {
    display: grid;
    grid-template-columns:repeat(2,1fr);
    align-items: center;

    p{
      background: #000000 ;
      border-radius: 3px ;
      margin-left:10px;
      margin-right: 10px;
      color:#fff ;
      font-weight: 300;
      line-height: 23px;
      padding-top: 10px;
      padding-bottom: 10px;
      
    }

    p:hover{
      background: #001eb7 ;
      transition: all 500ms ease;
      padding-top: 10px;
      cursor:pointer;
      
    }
     

  }
   



  @media screen and(min-width:0px) and (max-width:500px) {

    
    
    p{
      
      font-size: 14px;
      line-height: 23px;
      
    }

    strong {
      font-size: 29px !important;
      
    }
   

  }






}  

.grid-app-bar:hover {
  background: #a5935b33 !important;
  transition: all 500ms ease;
  
  box-shadow: 0px 0px 17px #000; 
  transition: all 500ms ease ;
  strong {
    transition: all 500ms ease ;
    
    color: #ffffff;
    
  }
}





.grid-app-bar-simple {
  display:grid;
  grid-auto-rows: minmax(40px, auto);
  grid-template-columns:repeat(1,1fr);
  align-items: center;
  border-radius: 2px !important;
  width:100% !important;
  margin-bottom: 10px;
  margin-top: 10px;
  background:#0f50ff1a !important;
  border-radius: 333px !important;
  transition: all 1000ms ease;
  border-radius: 40px;
  box-shadow: 0px 0px 17px #000;
  border:1px solid #e6af8769;
  padding:0.7rem 0px 0.7rem 0px  !important;
  

  h4 {
    display: none !important;
    
    
    
   }
  
  

  p {
    text-align: center;
    font-size: 25px;
    color:#37f2a1 ;
    font-weight: 500;
    justify-content: center; 
    line-height: 30px;
    span {
      text-align: center;
      font-size: 20px;
      color:#a2a2a2 ;
    }

    strong {
      font-size: 39px;
      font-weight: 300;
      color: #5bb3ff;
      text-shadow: 0px 0px 4px rgb(4, 54, 106);
      transition: all 500ms ease ;
    }
  }


  div {
    display: grid;
    grid-template-columns:repeat(2,1fr);
    align-items: center;

    p{
      background: #000000 ;
      border-radius: 100px ;
      margin-left:10px;
      margin-right: 10px;
      color:#fff ;
      font-weight: 300;
      line-height: 23px;
      padding-top: 10px;
      padding-bottom: 10px;
      
    }

    p:hover{
      background: #322f29 ;
      transition: all 500ms ease;
      padding-top: 10px;
      cursor:pointer;
      
    }
     

  }

  .grid-app-bar-simple:hover {
    background: #a5935b33 !important;
    transition: all 500ms ease;
    
    box-shadow: 0px 0px 17px #000; 
    transition: all 500ms ease ;
    strong {
      transition: all 500ms ease ;
      
      color: #ffffff;
      
    }
  }
  

  .grid-items-3 {
    grid-template-columns:repeat(3,1fr) !important;
  }
   



  @media screen and(min-width:0px) and (max-width:500px) {

    
    
    p{
      
      font-size: 14px;
      line-height: 23px;
      
    }

    strong {
      font-size: 29px !important;
      
    }
   

  }






}  



////////////////////// aqui vamos a colocar el grid sistem de las cuentas bancarias 
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios

.grid-app-bar-simple-ben {
  display:grid;
  grid-auto-rows: minmax(40px, auto);
  grid-template-columns:repeat(1,1fr);
  
  align-items: center;
  border-radius: 2px !important;
  width:100% !important;
  margin-bottom: 10px;
  margin-top: 10px;
  background:#2e2e2e5e !important;
  // border-radius: 333px !important;
  transition: all 1000ms ease;
  border-radius: 40px;
  box-shadow: 0px 0px 17px #000;
  border-bottom:1px solid #e6af8769;
  padding:0.2rem 20px 0.2rem 20px  !important;
  
.active-selection-proyectos {
  background: none !important;
  border-radius: 0px ;
  border-left:none !important;
  border-top:none !important;
  border-right:none !important;
  border-bottom:8px solid #e8e5dce2 !important;
  box-shadow: none !important;
  
  h4 {
    display: none !important;
    
    
    
   }

   p {
     color: #fff !important;
   }

}


  h4 {
    display: none !important;
    
    
    
   }
  
  

  p {
    text-align: center;
    font-size: 15px;
    color:#37f2a1 ;
    font-weight: 500;
    justify-content: center; 
    line-height: 30px;
    border-bottom: 1px solid rgba(194, 194, 194, 0.071);
    span {
      text-align: center;
      font-size: 20px;
      color:#a2a2a2 ;
    }

    strong {
      font-size: 39px;
      font-weight: 300;
      color: #5bb3ff;
      text-shadow: 0px 0px 4px rgb(4, 54, 106);
      transition: all 500ms ease ;
    }
  }


  div {
    display: grid;
    grid-template-columns:repeat(2,1fr);
    align-items: center;

    p{
      
      border-radius: 0px ;
      margin-left:10px;
      margin-right: 10px;
      color:#fff ;
      font-weight: 300;
      line-height: 23px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 8px solid rgba(0, 0, 0, 0);
      
    }

    p:hover{
      background: none ;
      transition: all 500ms ease;
      padding-top: 10px;
      border-bottom: 8px solid rgba(247, 222, 175, 0.35);
      cursor:pointer;
      
    }
     

  }

  .grid-app-bar-simple:hover {
    background: #a5935b33 !important;
    transition: all 500ms ease;
    
    box-shadow: 0px 0px 17px #000; 
    transition: all 500ms ease ;
    strong {
      transition: all 500ms ease ;
      
      color: #ffffff;
      
    }
  }
  

  .grid-items-3 {
    grid-template-columns:repeat(3,1fr) !important;
  }

  .grid-items-4 {
    grid-template-columns:repeat(4,1fr) !important;
  }
   



  @media screen and(min-width:0px) and (max-width:500px) {

    
    
    p{
      
      font-size: 14px;
      line-height: 23px;
      
    }

    strong {
      font-size: 29px !important;
      
    }
   

  }






}  

////////////////////// aqui vamos a colocar el grid sistem de las cuentas bancarias 
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios
/////////////////////////////////beneficiarios









.desactive-selection-proyectos {
 background: rgba(135, 135, 135, 0.455) !important;
 color: #989898 !important; 
 border: 1px solid #b5b5b5;
 cursor:no-drop !important;
}

///// aqui vamos a colocar el evento

.active-selection-proyectos {
  
  background: linear-gradient(to top left, #000000, #000000ba), url(./Panther-pattern-7.png) !important;
 background-position: 50% 60% !important;
 background-size:100% !important;
 background-repeat:repeat !important;
box-shadow: 0px 0px 6px #000 !important;
border: 1px solid #f7ec75 !important;
line-height: 19px !important;
transition: all 500ms ease;
color: #ffe3ae ;
font-weight: 600 !important;
position:relative !important;
justify-content: center;
display: flex ;
align-items: center;
filter: saturate(30%);



@media screen and(min-width:0px) and (max-width:500px) {
  
  h4 {
    display: flex ; 
    opacity: 0 !important;
  }


} 


h4:after{
  position: absolute;
  top:-10px;
  right:-20px;
  content:'';
  height:20px;
  width: 20px;
  background: rgba(0, 0, 0, 0.523);
  transform:rotate(45deg);
  border:1px solid rgb(255, 204, 1);
  box-shadow: 0px 0px 5px #000;
  
}


h4:before{
  position: absolute;
  top:-10px;
  left:-20px;
  content:'';
  height:20px;
  width: 20px;
  background: rgba(0, 0, 0, 0.523);
  transform:rotate(45deg);
  border:1px solid rgb(255, 192, 1);
  box-shadow: 0px 0px 5px #000;
  
}

h4 {
 display: none !important;
 z-index: 0;
 position: absolute;
 background: rgba(247, 202, 24, 0.708);
 height: 1px;
 border-radius: 100px;
 width: 0%;
 
 
 border: 1px solid #0000;
 color: #ffffff;
 transition: all 500ms ease;
 animation: animation-s 1000ms ease-in-out 0ms infinite alternate;
 margin: auto;
 
 
 
}






p{
 color:#a9a9a9;
 font-weight: 300 !important;
 font-size:20px !important;
 animation:animation-open 600ms ease-in-out 0ms 1 normal;
 z-index: 7 !important;
 
}
} 
 




@keyframes animation-s {

  0% {
    background:rgba(234, 207, 2, 0.27);
     width: 35%;
     opacity: 0;
  } 

  100%{
    background:rgba(255, 183, 0, 0);
   width: 50%;
   opacity: 1;
  }


}



@keyframes animation-on-hover-bg-proyectos {
0% {
 background-size:70% !important;
   
   
}
100% {
   
 background-size:250% !important;
}

} 


.card {
  background:linear-gradient( to top left,  #000000 ,rgba(0, 0, 0, 0.824));
  overflow: hidden;
  position: relative;
  z-index:0;
  border: 1px solid #bcbcbca9 !important;
  margin-bottom: 10px !important;
} 

.card::after {
  position: absolute;
content: '';
width: 100%;
height: 100%;
background: linear-gradient( to bottom, #81f7ff52 ,#7df0de48);
filter: blur(100px);
z-index: -1;
}

.table-export-justify {
  display:grid;
  grid-auto-rows: minmax(50px, auto);
  grid-template-columns:repeat(2,1fr);
  align-items: center;
  text-align: left;
  padding-left: 20px;
  font-size: 13px;
  padding-bottom: 10px;

  @media (max-width: 992px) {
    justify-content:flex-start;
  }

}  

.flex-direction-collumn-panther { 
  flex-direction: column !important;
  
} 



.btn-maps-index {
  z-index: 99999;
} 

////////////////////////////////////////


.grid-dash {
  display: grid;
  grid-auto-rows: minmax(70px, auto);
  grid-template-columns:repeat(4,1fr); 
  align-items: center;
  column-gap: 12px;
  margin-bottom: 10px;
  row-gap: 10px;
  
  
  
  


  h5 , h3 {
    margin-bottom: 4px !important;
  }

  h5 {
    font-size: 24px;
    line-height: 17px;
    
    
    
  }



  h3 {
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 2px; 

    div{
      white-space: nowrap;
    }
    
    
  }

  i  {
    margin: 2px;
  } 


    
  
  @media screen and(min-width:0px) and (max-width:500px) {
  
    grid-template-columns:repeat(1,1fr);
    grid-auto-rows: minmax(70px, auto);

    h3 {
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 30px;
      
    }
 

  } 

  
  @media screen and(min-width:500px) and (max-width:768px) {

    grid-template-columns:repeat(2,1fr);
    grid-auto-rows: minmax(70px, auto);

    h3 {
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 30px;
      
    }
  

  } 



  @media screen and(min-width:992px) and (max-width:1300px) {

    

    h5 {
      
      font-size: 14px;
      
    }

    h3 {
      
      font-size: 17px;
      
    }
  

  }



} 



.dashstyle-box{
  border:1px solid #b7b7b7ae;
  background: linear-gradient(to bottom left, #0412391f, #0012412b);
  border-radius: 2rem;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: grid;
  position: relative;


   i {
     color: #f0c440d6 !important;
     text-shadow: 1px 1px 7px rgb(21, 113, 212);
     font-size: 15px !important;
     background: rgb(0, 0, 0);
     padding: 4px;
     border-radius: 2px !important;
     margin: 7px !important;
     border:1px solid #b7740074 !important;
     box-shadow:0px 6px 1px #0000 !important;
     position: absolute;
     bottom:-14px;
     left: -14px;
     animation: animation-open 550ms ease-in-out 0ms 1 normal; 
     filter: saturate(10%);
     
     
   } 
   @keyframes animation-open {
    from {
      opacity: 0;
    }
    to {
     opacity: 1;
   }

  }

  



  @media screen and(min-width:768px) and (max-width:992px) {

    
    padding: 6px;
  

  }


} 


//////////////////////////////////////////////


/// aqui vamos a cpolocar el responsive de los
//// filtros la nueva maquetacion 





///////////////// vamos a colocar las animaciones de las cuentas///////////
.animation-cuentas{
  animation: animation-trans 550ms ease-in-out 0ms 1 normal; 
}

@keyframes animation-trans {

  from{ 
      margin-top: 4px;
      opacity: 0;
      background-size:20%;
      transform: translateX(1000px)

  }

to{
  margin-top: 0px;    
  opacity: 1;
  background-size:100%;
      
  }


} 

.animation-cuentas-zoom {
  animation: animation-trans-zoom 150ms ease-in-out 0ms 1 normal; 
}


@keyframes animation-trans-zoom {

  from{ 
      
      opacity: 0;
      
      transform: scale(400%)

  }

to{
  transform: scale(100%);
  opacity: 1;
  
      
  }


}  


.animation-cuentas-up {
  animation: animation-trans-up 750ms ease-in-out 0ms 1 normal; 
}


.animation-delay-1 {
  animation-delay: 5ms;
}

.animation-delay-2 {
  animation-delay: 25ms;
}
.animation-delay-3 {
  animation-delay: 45ms;
}


@keyframes animation-trans-up {

  from{ 
      
      opacity: 0;
      
      transform: translateY(400px)

  }

to{
  
  opacity: 1;
  
      
  }


} 

.icon-joe-1 {
   
   i{
    animation:joe-icon-animation 1110ms ease-in-out 0ms infinite alternate;
    border-radius:2px;
   }
} 


.icon-joe-2 {
   
  i{
   animation:joe-icon-animation 1190ms ease-in-out 0ms infinite alternate;
   border-radius:2px;
  }
} 

.icon-joe-3 {
   
  i{
   animation:joe-icon-animation 1100ms ease-in-out 0ms infinite alternate;
   border-radius:2px;
  }
} 

.icon-joe-4 {
   
  i{
   animation:joe-icon-animation 1150ms ease-in-out 0ms infinite alternate;
   border-radius:2px;
  }
} 


@keyframes joe-icon-animation {

  from {
    color : rgb(22, 91, 242);
    
    
  } 
  to {
    color : rgba(216, 155, 48, 0.989);
    filter: saturate(40%);
  }

}


.progress-joe-label {
  
  
  width:100%;
  border-radius: 30px!important;
  
  border: 1px solid #ee8805;
 filter: saturate(32%);
  
  display: grid !important;
  grid-template-columns:20% 60% 20%!important;
  grid-auto-rows: minmax(35px, auto);
  margin-bottom: 10px !important;
  align-items: center; 
  box-shadow: 0px -6px 24px #000;

 span {
   color:rgb(255, 234, 114);
   padding-left: 1px;
   padding-right: 1px;
   font-size: 10px;
   font-weight: 500;
   text-shadow: 0px 0px 4px rgba(3, 154, 255, 0.467);
   letter-spacing: 1px;
 }


  P {
    
    margin:0px !important;
    height: 100%;
    align-items: center;
    display: grid;
    
    color: rgb(255, 255, 255);
    font-size: 10px;
    font-weight: 900;
    
    
  }
} 


.header-container-pro {
    display:grid;
    grid-template-columns:65% 35%;
    grid-auto-rows: minmax(20px, auto);
    align-items: center; 


    @media screen and(min-width:0px) and (max-width:650px) {
    
      
      grid-template-columns:100% !important;
    
    } 


    .MobileSteper-Fix {
      margin-top: 0px !important;
      font-size: 13px;
      padding-bottom: 2px;
      padding-top: 2px;
      background: #0a2187 !important;
      border: 1px solid #ee8805;
      filter: saturate(52%);
    } 

    span {
      font-size: 10px;
      
    }



}



.grid-progress { 
      width: 80%;
      margin:0 auto;
      display: grid;
      grid-template-columns:100% !important; 
      column-gap: 5px; 
      position: relative;

  @media screen and(min-width:0px) and (max-width:500px) {
    width: 95%;
    padding-left: 30px;
    padding-right: 30px;
  
  } 

}






.progress-joe {
  
  // animation: bg-tooltips2 1000ms ease-in-out 0ms infinite alternate;
  width:100%;
  
  border-radius: 30px!important;
  box-shadow:0px 0px 9px rgba(0, 0, 0, 0.352);
  
  display: grid !important;
  grid-template-columns:21% 58% 21%!important;
  grid-auto-rows: minmax(10px, auto);
  margin-bottom: 0px;
  position: absolute;
  height: 38px;
  z-index: -3;
  align-items: center;
   

  
  @media screen and(min-width:0px) and (max-width:400px) {
    grid-template-columns:30% 40% 30%!important;
  
  } 



  div {
    height: 15px !important;
    border-left: none !important;
    border-right: none !important;
    
    border-bottom:none !important;
    div {
      background: rgba(27, 45, 91, 0.77);
      border-radius: 2px;
      
      ;

      div {
        
        transition: all 500ms ease;
         background: linear-gradient(to left, #51ecbd2c, #2595c94d, #79fcd52a), url(./Panther-pattern-7.png) ;
        box-shadow: 0px 0px 10px #086385;
        // background: rgb(13, 155, 65);
        // background: rgb(19, 240, 100);
        border-right: 1px solid #000;
        background-position-x: 0%;    
        background-size:120% ;
        background-repeat:repeat !important;
        border-radius: 2px!important;
        position: relative;
       animation:bg-move-good-2 500ms ease 0ms infinite normal !important;
        opacity: 0.90;

      }
      div:after {
        
        content: "";
        position: absolute;
        top:0;
        left:0;
        background: rgba(87, 255, 121, 0.679);
        height: 100%;
        animation:bg-move-good-3 4500ms ease 0ms infinite normal !important;
        border-radius:2px!important;
        // border-top:1px solid #33ff00;
        // border-bottom:1px solid #33ff00;
        
      }

   

    }
  } 


  @keyframes  bg-move-good-3 {

    0%{
      width: 0%;
      
  
    }
  
    100%{
      width: 100%;
      
      
    }
  
  } 


  P {
    
    margin:0px !important;
    height: 100%;
    align-items: center;
    display: grid;
    
    
    color: #ffff;
    font-size: 13px;
    font-weight: 900;
    
  }
} 


@keyframes  bg-move-good-2 {

  0%{
    background-size:120% ;
    background-position-x:100%;    

  }

  100%{
    background-size:120%  ;
    background-position-x: 0%;    
    
  }

} 

@keyframes  bg-tooltips2 {

  0%{
      
      background:#00040b ;

  }

  100%{
    
      background:#06045c ;
    
  }

}
 


////// dashboard grid-template//////////


.grid-dashboard-template {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:50% 50%; 
    column-gap: 5px;

    .grid-dash-element-1{
      padding: 0px !important;

      
    } 

    .grid-dash-element-2{
      padding: 0px !important;


      div {
        padding: 2px 3px 1px 3px  ;
      }
    } 

    @media screen and(min-width:0px) and (max-width:1400px) {

      
      grid-template-columns:100% !important; 
      
    
  
    }

    ///// celulares exactos
    @media screen and(min-width:0px) and (max-width:370px) {

    
      padding: 0px;
    
  
    }

    ///// celulares exactos
    @media screen and(min-width:370px) and (max-width:500px) {

    
      padding: 0px;
    
  
    }


    @media screen and(min-width:501px) and (max-width:992px) {

    
      padding: 0px;
    
  
    }  


    @media screen and(min-width:993px) and (max-width:1400px) {

    
      padding: 0px;
    
  
    } 




}  


.box-product-info {
  width:90%;
  margin:0 auto;
}




.grid-imagen-info-prod {  

  display: grid;
      
      grid-template-columns:100%; 
      column-gap: 5px; 
      margin-bottom: 20px;

      
      p {
        margin-bottom: 0px;
      } 

      strong {
        font-weight: 900;
      } 


      @media screen and(min-width:0px) and (max-width:500px) {

    
        padding-left: 30px;
        padding-right: 30px;
        


        .elemento1{
          row-gap: 10px; 
          

        }

        .elemento2{
          grid-template-columns:100% !important;   
          row-gap: 10px;
          

          h2 {
            text-align: center;
          }

          p{
            text-align: center;
          }


        }

      
    
      } 

  
  .elemento1 {
    display: grid;
    grid-auto-rows: 120px;
    
    grid-template-columns:100%; 
    text-align: center;
    
    line-height:27px ;
    align-items: center; 
    font-size: 20px; 

    color: #fff; 
    justify-content: center;
    


    @media screen and(min-width:0px) and (max-width:500px) { 
      font-size: 20px; 
      margin-bottom: 20px;
      margin-top: 20px;
      }


    span {
      font-size: 13px;
      color: #fff;
      font-weight: 900;
    } 

    div{
      display: grid;
      width: 80%;
      margin:0 auto;  
      
      


      @media screen and(min-width:0px) and (max-width:500px) { 
          width: 90%;
      }

      
    }


      

  
  }

  .elemento2 {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:65% 35%; 
    align-items: center;
    column-gap:10px ;

    div {
      display: grid;
      justify-content: start;
      border-radius: 10px;
    } 

    .contenedor {
      margin-bottom: 0px;
      font-size: 13px;
      padding-left: 10px;
      
    overflow-y:scroll;
    white-space:wrap;
    text-overflow: ellipsis;
    height:120px !important;
    
      
    } 


    .contenedor::-webkit-scrollbar {
    -webkit-appearance: none;
      }

      .contenedor::-moz-scrollbar {
        -webkit-appearance: none;
          }

.contenedor::-webkit-scrollbar:vertical {
    width:10px;
}

.contenedor::-moz-scrollbar:vertical {
  width:10px;
}
.contenedor::-moz-scrollbar:vertical {
  width:10px;
}

.contenedor::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.contenedor::-moz-scrollbar-button:increment,.contenedor::-moz-scrollbar-button {
  display: none;
} 

.contenedor::-webkit-scrollbar:horizontal {
    height: 10px;
}

.contenedor::-moz-scrollbar:horizontal {
  height: 10px;
}

.contenedor::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.contenedor::-moz-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.contenedor::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.contenedor::-moz-scrollbar-track {
  border-radius: 10px;  
}


  }


}

.grid-productos-container {
      display: grid;
      
      grid-template-columns:100%; 
      column-gap: 5px; 

      
      p {
        margin-bottom: 0px;
      } 

      strong {
        font-weight: 900;
      }


      @media screen and(min-width:0px) and (max-width:500px) {

    
        padding-left: 30px;
        padding-right: 30px;


        .elemento2{
          grid-template-columns:100% !important;   
        }
      
    
      } 


      .elemento1 {
        display: grid;
        grid-auto-rows: minmax(30px, auto);
        grid-template-columns:100%; 
        text-align: center;
        
        align-items: center; 


          p {
            font-size: 20px;
            color: #fbe3d2;
            // color:#f49855;
          }

      
      }

      .elemento2 {
        display: grid;
        grid-auto-rows: minmax(60px, auto);
        grid-template-columns:33% 33% 33%; 
        align-items: center;
        column-gap: 4px;
        transition:all 500ms ease;

        div {
          background: #123eaf16;
          box-shadow: 0px 0px 1px #000;
          
          
          


          padding: 10px 0px 10px 0px;
          border-bottom: 1px solid rgb(255, 155, 0);
          border-radius: 2px;
          filter: saturate(28%);
          transition:all 500ms ease;
        } 

        p {
          margin-bottom: 0px;
          font-size: 11px;
          line-height: 11px;
          
        } 


      


      }

      .elemento2   div:hover {
        filter: saturate(390%);
        transition:all 500ms ease;
        cursor:pointer;
        
      }


      .elemento3 {
        display: grid;
        grid-auto-rows: minmax(20px, auto);
        grid-template-columns:100%; 
        text-align: center;
        
        align-items: center;  
        font-size: 20px;


        p {
          border: 1px solid #136cda;
          border-radius: 7px;
          
        }


      
      }

      .elemento4 {
        display: grid;
        grid-auto-rows: minmax(60px, auto);
        grid-template-columns:25% 25% 25% 25%; 
        text-align: center;
        
        align-items: center; 


        i {
          font-size: 25px;
          color:rgba(20, 96, 189, 0.961);
          font-weight: 900;
        } 


        @media screen and(min-width:0px) and (max-width:650px) {
    
      
          grid-template-columns:50% 50% !important;
        
        } 
      
      } 


      .elemento5 {
        display: grid;
        grid-auto-rows: minmax(30px, auto);
        grid-template-columns:100%; 
        text-align: center;
        z-index: 999 !important;
        
        align-items: center; 


          p {
            font-size: 20px;
          }

      
      }


} 


.btn-box-invest {
 width:55%;
 margin: 0 auto;
 margin-top: 0px !important; 
 position: relative;

 @media screen and(min-width:0px) and (max-width:500px) {

  width:90%;
  padding-left: 30px;
  padding-right: 30px;




} 

} 


.btn-box-invest::after {
  height: 1px;
  width: 100% ;
  text-align:center;
  bottom: 23px;
  right: 0px;
  content: "www.panther7.com";
  position: absolute;
  z-index: 0;
  font-size: 8px;
  opacity: 0.50;
  color: rgb(0, 70, 247);

  
  
  
}

.btn-box-invest::before {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  bottom: 3px;
  left: 0px;
  content: "";
  position: absolute;
  z-index: 0;
  background: #ff8400 !important;
  animation: time-nav-btn 6.8s ease infinite alternate-reverse;
  box-shadow: 0px 9px 0px rgba(132, 99, 1, 0.064);
}

@keyframes time-nav-btn{
  from{
    width: 66%;
  }

  to{
    width: 100%;
  }
}




///// este box-grid representa a row y a col del flexbox

.box-grid {
  // display:grid;
  // grid-auto-flow: row;
  // flex-wrap: wrap;

  .box-grid-son { 

    display:grid;
    grid-auto-rows: minmax(100px, auto) !important;
    grid-template-columns: 50% 50%;
    grid-auto-flow: row !important; 
    column-gap: 5px;
    row-gap:14px;


    @media screen and(min-width:0px) and (max-width:500px) {

      grid-template-columns: 100% !important;
      padding-left: 30px;
      padding-right: 30px;
    
    
    
    
    } 
    

  }


}


//// aqui vamos a hacer el responsive de las cajas en responsive
.main-panel .content {

  
  @media screen and(min-width:0px) and (max-width:500px) {

    
    padding-left: 7px;
    padding-right: 7px;
  
  
  
  
  } 

} 

.box-grid-header {

  padding:20px !important;

  h2 {
    margin: 0;
    font-size: 30px;
    font-weight:900;
    padding-bottom: 2px;
    border-bottom: 1px solid rgb(74, 74, 74);
  }


.elemento1-mov-header{

  p {
    text-align:center;
  }
  
  }

.elemento2-mov-header{ 

  p {
    text-align:center;
    font-weight: 900;
  }

 div {
   background: #000935;
   text-align: center;
   border:1px solid rgb(0, 98, 245);
   border-radius: 100px;
 }

 div:hover {
  background: #002aff;
  transition: all 500ms ease;
  cursor:pointer;

 }
}


}



/// aqui vamos a colocar el grid sistem de los ultimos movimientos
.ultimos-grid-movimientos {
  grid-auto-rows: minmax(60px, auto);
    grid-template-columns:100%; 

    background:linear-gradient(to bottom, #040f27e8 10%, rgba(27, 42, 75, 0.75) 50%,#000b23cf 90%), url(./Panther-pattern-7.png) ;
  background-position: 50% 60%;
  background-size:100%;
  background-repeat:repeat;

  display:grid;
  
  
  padding: 20px !important;
  border:1px solid #3c59a2;
  border-radius: 3px;

  i {
    font-size: 30px;
    color:rgba(0, 237, 201, 0.263);
    text-shadow:0px 0px 1px #fff;
  }
  



  strong{
    font-weight: 900;
  }

  .elemento1-mov {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:50% 50%; 
    align-items: center;
    padding:10px;
    column-gap:2px;

    div {
      background: #011a3c;
      box-shadow:0px 0px 0px rgb(48, 48, 48);
      border-radius: 10px;
      text-align:center;
    } 

    div:hover {
      transition: all 500ms ease;
      background: #1a6de1;
    }


    p {
      margin-bottom: 0px;
    }


  }



  .elemento2-mov {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:50% 50%; 
    align-items: center;
    column-gap:2px;
    text-shadow:0px 0px 1px #a59a47;

    div {
      background: #011a3c;
      border-radius: 10px;
      text-align:center;
      box-shadow:0px 0px 0px rgb(48, 48, 48);
      transition: all 500ms ease;
    } 

    div:hover {
      background: #1a6de1;
      transition: all 500ms ease;
    }

    p {
      margin-bottom: 0px;
    }


  } 


  .elemento3-mov {
    display: grid;
    
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:100%; 
    align-items: center;

    div {
      background: #0f1721;
      border-radius: 3px;
      text-align:center;
      border-bottom:1px solid #e8cf90;
      padding: 10px;
      font-size: 10px;
      transition:all 500ms ease;
      
    } 

    div:hover {
      transition:all 500ms ease;
      background: #001fb74c;
      
    }


    strong {
      transition: all 500ms ease;
    }
 


    p {
      margin-bottom: 0px;
    }


  }



}




/////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

//// aqui vamos a colocar  la lista de proyectos

.proyectos-grid-pro {
  grid-auto-rows: minmax(60px, auto);
    grid-template-columns:100%; 

    background:linear-gradient(to bottom, #040f27e8 10%, rgba(63, 120, 246, 0.84) 50%, #000b23cf 90%), url(./Panther-pattern-7.png) ;
  background-position: 50% 60%;
  background-size:100%;
  background-repeat:repeat;

  display:grid;
  
  
  padding: 20px !important;
  border:1px solid #3c59a2;
  border-radius: 3px;

  i {
    font-size: 17px;
    color:rgba(0, 237, 201, 0.263);
    text-shadow:0px 0px 1px #fff;
  }
  
  h3 {
    font-size: 20px !important;
    margin: 0px;
    line-height:21px;
    background: rgb(0, 81, 255);
    
    align-items: center;
    margin: auto 0;
    padding:4px ;
    border-radius: 4px;
    span {
      border-top:1px solid rgb(232, 212, 116);
      font-size: 10px !important;
    }
  }



  strong{
    font-weight: 900;
    font-size: 9px;
  }

  .elemento1-pro {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:25% 25% 25% 25%; 
    align-items: center;
    padding:10px;
    column-gap:2px;

    div {
      background: #011a3c;
      box-shadow:0px 0px 0px rgb(48, 48, 48);
      border-radius: 10px;
      text-align:center;
    } 

    div:hover {
      transition: all 500ms ease;
      background: #1a6de1;
    }


    p {
      margin-bottom: 0px;
    }


  }



  .elemento2-pro {
    display: grid;
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:33% 33% 33%; 
    align-items: center;
    column-gap:2px;
    row-gap: 2px;
    text-shadow:0px 0px 1px #a59a47;
    padding:10px;

    div {
      background: #00204bba;
      border-radius: 10px;
      text-align:center;
      box-shadow:0px 0px 0px rgb(48, 48, 48);
      transition: all 500ms ease;
    } 

    div:hover {
      background: #1a6de1;
      transition: all 500ms ease;
    }

    p {
      margin-bottom: 0px;
    }


  } 


  .elemento3-pro {
    display: grid;
    
    grid-auto-rows: minmax(60px, auto);
    grid-template-columns:50% 50%;   
    align-items: center;

    div {
      background: #0f1721;
      border-radius: 3px;
      text-align:center;
      border-bottom:1px solid #e8cf90;
      padding: 10px;
      font-size: 10px;
      transition:all 500ms ease;
      
    } 

    div:hover {
      transition:all 500ms ease;
      background: #001fb74c;
      
    }


    strong {
      transition: all 500ms ease;
    }
 


    p {
      margin-bottom: 0px;
    }


  }



}

.box-grid-pro {
  // display:grid;
  // grid-auto-flow: row;
  // flex-wrap: wrap;

  .box-grid-pro-son { 

    display:grid;
    grid-auto-rows: minmax(100px, auto) !important;
    grid-template-columns: 50% 50%;
    grid-auto-flow: row !important; 
    column-gap: 5px;
    row-gap:44px;
    margin-top:20px !important;



    @media screen and(min-width:0px) and (max-width:650px) {

      grid-template-columns: 100% !important;
      padding-left: 30px;
      padding-right: 30px;
    
    
    
    
    } 
    

  }


} 

.bb-btn-grid {
  display:grid;
  align-items:center;
} 

.card-panther {
  width: 100%;
  padding:30px;
  padding:30px;  
  height: 100%;
} 






.listado-proyectos-box {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-auto-flow: row;
  align-items: center;
  column-gap:5%;
    row-gap:30px;
    position: relative;
    transition: all 500ms ease;
    justify-content: center;
    

    .contenedor-grid {
      grid-auto-columns: 100px !important;
      border: 1px solid #000;
      background: #05081c;
      border-radius: 2px !important;
      border: 1px solid #0055c4 !important; 
      transition: all 500ms ease;
      position: relative;
      filter:saturate(65%);

    
      
    }



    .contenedor-grid:hover {
      background:rgba(14, 10, 0, 0.66) !important;
      transition: all 500ms ease;
      border: 1px solid #ff9900 !important;  
      color: #000 !important;
      


      .listado-proyectos-imagen {
      
        
        
        img {
          
          position:absolute;
          opacity: 0.15;
          transition: all 500ms ease;
        
        }
           
      
      
      }
      

      .listado-proyectos-footer{
        display: grid;
        opacity: 1;
        transition: all 500ms ease;
        
      } 


      .listado-proyectos {
        strong {
          color: #fff99e;
          transition:all 500ms ease;
        }

        div {
          color: #fff99e;
          transition:all 500ms ease;
          background:rgb(1, 21, 39) !important;
          border:1px solid #7d5a01;
        }




      }


    }

    

      
  
} 

.listado-proyectos-imagen {
  width:100%;
  background: #000;
  display: grid;
  justify-content: center;
  max-height:140px ;
  overflow-y: hidden;
  border-radius: 10px 10px 0px 0px;
  
  
  img {
    
    position:absolute;
    opacity: 0.01;
    border-radius: 10px 10px 0px 0px;
    width:100%;
    max-height:100%;      
    transition: all 500ms ease;
  
  }
     


} 



.elemento-pro-2-imagen{
  height:110px ;

  box-shadow: 0px 0px 4px #000;
  z-index:8;
  border-radius: 30px 30px 0px 0px;
  position: relative;
  background: #000;
  z-index: -1;
  width: 100%;
  overflow-y: hidden;
  
  
img {
  
  top:-66%;
  border-radius: 30px 30px 0px 0px;
  position:absolute;
  opacity: 0.69;
  width: 100%;
  
  transition: all 500ms ease;

}
   


} 


.separador-box {
  row-gap:15px !important;
  div {
    height: 100px;
    background: rgb(0, 0, 0) !important;
  }
 
} 


.desaturador-box {
  filter: saturate(100%) !important;
}


///@at-root
.listado-proyectos-box-simple {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  align-items: center;
  column-gap:5%;
    row-gap:0px;
    position: relative;
    transition: all 200ms ease;
    justify-content: center;
    

    .contenedor-grid-simple {
      grid-auto-columns: 100px !important;
     border: 1px solid rgba(0, 0, 0, 0);
       filter: saturate(40%);
      border-radius: 1px !important;
      // border: 1px solid #d6e5ff00 !important; 
      transition:all 200ms ease;
      position: relative;
       
      // div {
        
      //     background: #0070ff;
        
      // }

    
      
    }


    ////// aqui esta el hover de las mas calientes
    .contenedor-grid-simple:hover {
      background:rgba(1, 44, 109, 0) !important;
      transition:all 200ms ease;
      // border: 1px solid #ffc061 !important; 
      
      color: #000 !important;
      cursor: pointer;
      filter: saturate(100%);
       transform: scale(0.97);
      


      .listado-proyectos-imagen-simple {
      
        
        
        img {
          
          position:absolute;
          opacity: 0.15;
          transition:all 200ms ease;
          filter: blur(2px);
        
        }
           
      
      
      }
      

      .listado-proyectos-footer-simple{
        display: grid;
        opacity: 1;
        transition:all 200ms ease;
        
      } 


      .listado-proyectos-simple {
       small, strong {
          color: #fff99e;
          transition:all 200ms ease;
        }

        div {
          color: #fff99e;
          transition:all 150ms ease;
          
           background:rgba(68, 39, 16, 0.46) !important;
          // border:1px solid #7d5a01;
          
          
        }




      }


    }

    

      
  
} 

////
.listado-proyectos-imagen-simple {
  width:100%;
  background: #000;
  display: grid;
  justify-content: center;
  max-height:140px ;
  overflow-y: hidden;
  border-radius: 10px 10px 0px 0px;
  
  
  img {
    
    position:absolute;
    opacity: 0.01;
    border-radius: 10px 10px 0px 0px;
    width:100%;
    max-height:100%;      
    transition: all 500ms ease;
  
  }
     


} 




///// aqui vamos a colocar para el boton flotante

 .box-up-convert{
   height: 100% !important;
   border: 1px solid rgba(0, 0, 0, 0);
    // overflow-y: scroll;
   margin-bottom:10px;
   margin-top:10px;
   position: relative;
 }





.busqueda-style{
  background: linear-gradient( to top right, rgb(0, 87, 162), rgba(13, 137, 151, 0.913)) !important;
  transition: all 500ms ease;
  color:#fff !important;
}

// .busqueda-style::before{
//   content: "Filtrando";
//   text-align: center;
//   position: absolute;
//   font-size: 9px;
//   width: 100%;
// bottom:-0px;

// }


////// postiion 


.box-relative-flotante {
  

  
  height: 100px ;
  
  
  position: relative;
  top:0px;
  left:0px;
  
  
} 



.flotante {
  
  position: fixed;
  
  width: 100%;
  z-index: 2; 
  padding:78px 30px 30px 284px;
  z-index: 1000;
  .flotante-header-proyectos {
    width:100%;  

      div {
        background: #011a3c;
      }

  }
}

.no-flotante {
  
  position: relative;
}





.box-relative-flotante::after {
  content: "";
  height: 100px;
  width: 100%;
  position: absolute;
  z-index: 0;
  top:0;
  left:0;
  display: grid;
} 


.glass-box{
  height: 100px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 0;
  display: grid;
  

  .glass-son{
    height: 100%;
    width: 100%;
    position: absolute;
    
    
    z-index:0;
    display: grid;
     filter: blur(2px);
     background: rgb(9, 23, 55);
     
    // background: linear-gradient(to bottom left,#0c3057d7, #1e3361de), url(./Panther-pattern-7.png) ;
    //   background-position: 50% 60% !important;
    //   background-size:100% ;
    //   background-repeat:no-repeat ;
    
  
  }
  

}

.flotante-header-proyectos {

  display:grid;
  grid-template-columns: 3% 18.3% 7.3% 7.3% 8.3% 6.3% 6.3% 6.3% 6.3% 7.3% 8.3% 8.3% 6.6%;
  position: relative;
  top:0px;
  z-index: 10;
  width:100%;
  height: 100px !important;
  border-bottom:1px solid #a48034ae !important;
  box-shadow: 0px 10px 10px #000 !important;



  div {
     background: rgba(242, 242, 242, 0);
       background-position: 50% 60% !important;
       background-size:100% ;
       background-repeat:no-repeat ;
        
      color :#ffffff;
      border-radius: 0px !important;
      padding: 6px;
      text-align: center;
      display: grid;
      align-items: center;
      grid-auto-flow: row;
      justify-content: center;
      
      line-height: 13px;
      transition: all 200ms ease;
      border:1px solid #b89d5900 !important;
    
    
    
    
    img {
      width:100%;
      height: 100%;
      transition: all 500ms ease;
    }
    
    
    strong {
      
      display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 14px; 
    transition: all 500ms ease;
    font-size: 13px ;

    /// esto es despues de las querys

    //  @media screen and(min-width:1710px) and (max-width:3350px) {
    //      font-size: 10px ;
    //  } 

    @media screen and(min-width:992px) and (max-width:1199px) {
        font-size: 7px ;
    } 

    @media screen and(min-width:1200px) and (max-width:1399px) {
      font-size: 8px ;
  } 

    @media screen and(min-width:1400px) and (max-width:1710px) {
      font-size: 10px ;
  } 


    } 


    span {

      font-family: 900;
      color: #fff;
      transition: all 500ms ease;
    } 


    small {

      color: #fff;
      font-weight:  400;
      font-size:8px;
      width: 100%;
      margin-top: 7px;
      transition: all 500ms ease;
    } 

  } 

  div:hover {
    
    transition: all 200ms ease;

  }



}



///// 

.contenedor-grid-simple:nth-child(even) {
  .listado-proyectos-simple {
    div {
      background:#483a1895 ;
      
    }
  }
  
  
}


.listado-proyectos-simple {
  display:grid;
  grid-template-columns: 3% 18.3% 7.3% 7.3% 8.3% 6.3% 6.3% 6.3% 6.3% 7.3% 8.3% 8.3% 6.3%;
  justify-content: center;
  width: 100%;
  column-gap:0px;
  row-gap:0px;
  
  text-align: center;
  padding: 3px;
  transition: all 500ms ease;
  
  div {
    
    background: #011a3c;
    color :#fff;
    
    padding: 6px;
    text-align: center;
    display: grid;
    align-items: center;
    grid-auto-flow: row;
    justify-content: center;
    text-align: center;
    line-height: 13px;
    transition: all 500ms ease;
    // border:1px solid #fff99e29;
    
    
    
    img {
      width:100%;
      height: 100%;
      transition: all 500ms ease;
      
    }
    
    
    strong {
      
      display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 14px; 
    transition: all 500ms ease;
    font-size: 9px !important;  

    /////// media querys de lista de proyectos cabeza

    @media screen and(min-width:1710px) and (max-width:3350px) {
      font-size: 13px !important;
  
    } 

    

    }


    span {

      font-family: 900;
      color: #fff;
      transition: all 500ms ease;
    } 


    small {

      color: #fff;
      font-weight:  400;
      width: 100%;
      margin-top: 7px;
      transition: all 500ms ease;
      font-size: 9px !important; 
      letter-spacing: -0.50px;


      /////// media querys de lista de proyectos body
    @media screen and(min-width:1710px) and (max-width:3350px) {
      letter-spacing: 0px;
      font-size: 13px !important;
  
    } 
    } 




  } 

    div:hover{
      
        transition: all 300ms ease;
        transition:all 500ms ease;

    }

 
}

///////@at-root

.listado-proyectos-header-simple {
  display:grid;
  grid-template-columns: 100%;
  justify-content: center;
  text-align: center;
  color: #ffff; 
  transition: all 500ms ease;

  div {
    font-weight: 400;
    font-size: 10px;
    padding-top: 20px;
    transition: all 500ms ease;

    strong {
      
      font-size: 20px;
      border-bottom: 1px solid #000;
      padding-left: 12px;
      padding-right: 12px;
      transition: all 500ms ease;
    }
  }

} 

.listado-proyectos-footer-simple {
display:grid;

opacity: 1;
grid-template-columns: 100%;
justify-content: center;
text-align: center;
transition: all 500ms ease;
height: 100%;

color: #ffff;


div {
  padding: 10px 0px 10px 0px ;
  margin: 10px 8px 10px 8px ;
border-radius:100px; 
transition: all 500ms ease;

}

} 

.listado-proyectos-foote-simpler:hover{
display: grid;
transition: all 500ms ease;


}


////









// .listado-proyectos-imagen:after {
//   position: absolute;
//   content: "";
//   top:0;  
//   left:0;
//   width:100%;
//   height:140px;     
//   background: linear-gradient( to top, rgba(7, 72, 152, 0.468), rgba(0, 0, 0, 0.777));
//   z-index: 999;
//   border-radius: 10px 10px 0px 0px;


// }


.listado-proyectos-header {
    display:grid;
    grid-template-columns: 100%;
    justify-content: center;
    text-align: center;
    color: #ffff; 
    transition: all 500ms ease;

    div {
      font-weight: 400;
      font-size: 10px;
      padding-top: 20px;
      transition: all 500ms ease;

      strong {
        
        font-size: 20px;
        border-bottom: 1px solid #000;
        padding-left: 12px;
        padding-right: 12px;
        transition: all 500ms ease;
      }
    }

} 

.listado-proyectos-footer {
  display:grid;
  
  opacity: 1;
  grid-template-columns: 100%;
  justify-content: center;
  text-align: center;
  transition: all 500ms ease;
  height: 100%;
  
  color: #ffff;
  

  div {
    padding: 10px 0px 10px 0px ;
    margin: 10px 8px 10px 8px ;
  border-radius:100px; 
  transition: all 500ms ease;

  }

} 

.listado-proyectos-footer:hover{
  display: grid;
  transition: all 500ms ease;
  
  
}




  .listado-proyectos {
    display:grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: center;
    width: 100%;
    column-gap:6px;
    row-gap:6px;
    
    text-align: center;
    padding: 10px;
    transition: all 500ms ease;
    

  
    
    div {
      background:#041e6d !important;
      color :#fff;
      border-radius: 4px;
      padding: 9px;
      text-align: center;
      display: grid;
      align-items: center;
      grid-auto-flow: row;
      justify-content: center;
      text-align: center;
      line-height: 13px;
      transition: all 500ms ease;
      border:1px solid #fff99e29;
      
      
      
      img {
        width:100%;
        height: 100%;
        transition: all 500ms ease;
      }
      
      
      strong {
        
        display: grid;
      grid-auto-flow: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 14px; 
      transition: all 500ms ease;

      }


      span {

        font-family: 900;
        color: #fff;
        transition: all 500ms ease;
      } 


      small {

        color: #fff;
        font-weight:  400;
        font-size:14px;
        width: 100%;
        margin-top: 7px;
        transition: all 500ms ease;
      } 



  
    } 

      div:hover{
        background:#0064fa !important;
          transition: all 300ms ease;
          transition:all 500ms ease;

      }

  
  } 



  .oleos-invertir{
    
     color: #fff;
     padding-left: 0px !important;
     padding-right: 0px !important;
     transition: all 700ms ease;
     z-index: 2;
     


     strong { 
       
       padding-bottom: 2px ;
       font-size: 30px;
       transition: all 700ms ease;
       
       
     }
}


.oleos-invertir:hover{
    
    transition: all 700ms ease;
    cursor:pointer; 


    strong { 
      border-bottom: 1px solid rgb(255, 205, 5);
      padding-bottom: 2px ;
      transition: all 700ms ease;
    }
} 



.contador-random {

  width: 100%;
  border:1px solid #000;

  height: 400px;

  background: #fff;
  display: grid;
  justify-content: center;
  align-items: center;

  button {
    display: grid;
    height: 40px;
    width: 100px;
    align-items: center;
  }
  

}

.border-panther7{
  border-top:1px solid rgba(225, 202, 145, 0.501) !important;
}

.yep-line-progress {
  display: grid;
  background: rgb(27, 235, 0);
  position: relative;
  height: 20px;
  
  
  z-index: 20;
  // content: attr(progressvalue);
} 





/////// retiro modal ///// estilos

.retiro-2 div {
  height: 80px;
  display:flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}


.retiro-3 div {
  height: 80px;
  display:flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.retiro-2 {
  display:grid ;
  grid-template-columns: 38% 38%;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
  color: #fff;
}

.retiro-1 {
  display:grid !important;
  grid-template-columns: 80% !important;
  justify-content: center !important;
  align-items: center !important;
}

.retiro-0 {
  display:grid !important;
  grid-template-columns: 100% !important;
  justify-content: center !important;
  align-items: center !important;
}




.retiro-3 {
  display:grid;
  grid-template-columns: 25% 25% 25%;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
  color: #fff;
  
}

.retiro-modal-dinamic {
  overflow-x: normal !important;
  position: relative;
  margin-top: 30px;
  
  height: 100%;
  display: grid;
  align-items: center;
}


.box-retiro-info {
  width: 100%;
  background:#000935 ;
  height: 100%;
  display: grid;
  padding: 20px 0px 20px 0px ;
  margin-top: 10px;
  border-radius: 19px;
  display: grid;
  align-items: center;
} 

.box-retiro {
  display: grid;
  grid-template-columns: 40% 40%;
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (min-width:501px) and (max-width:992px) {
    grid-template-columns: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (min-width:0px) and (max-width:500px) {
    grid-template-columns: 100%;
    padding-left: 10px;
    padding-right: 10px;
    transform: scale(0.90);
  }
  
  
  
  

}

@media screen and (min-width:0px) and (max-width:500px) {
  .transparent-bg-retiro-border p {
      width: 100% !important;
  }
}



.monto-dolares{
  position: relative;
  input {
    margin-top: 0px !important;
  }


  .height-11{
    font-size: 20px !important;
    height: 100%;
    width: 80px;
  }
}

.align-retiro-grid {
  display: grid;
  grid-template-columns: 40% 40%;
  justify-content: space-between;
  align-items: center;
  row-gap: 7px;
  margin-bottom: 20px;
  margin-top: 10px;


  p{
    text-align: left !important;
    background: linear-gradient( to right ,rgb(15, 96, 237), rgba(0, 0, 0, 0)) ;
    white-space: nowrap;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px !important;
    align-items: center;
    display: grid;
  }

  h5{
    text-align: right !important;
    font-size: 20px;
    border: 1px solid rgba(0, 98, 255, 0.724);
    margin-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 2px;
  }
  
}

.transparent-bg-retiro-border{
  background:transparent;
  border:none;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.transparent-bg-retiro-border h2 {
  font-weight: 700;
  border-bottom:2px solid rgb(8, 255, 156);
}
.transparent-bg-retiro-border p {
  font-weight: 300;
  font-size: 15px;
  padding-left: 8px;
  border-left:10px solid rgb(8, 255, 156);
  width: 360px;
  margin-bottom: 20px;

}


.calculo-retiro p {
  font-size: 15px;
}
.calculo-retiro {
display: grid;
grid-template-columns: 75%;
margin-left: auto;
margin-right: auto;
align-items: center;
justify-content: center;
text-align: center;
padding: 10px 0px 20px 0px;

}


.box-calculo-retiro {
  
  border-bottom:1px solid rgb(0, 140, 255);
  width: 100%;
  
}

.box-calculo-retiro span {
  font-weight: 900;

}


@media screen and (min-width: 0px) and (max-width: 992px) {
  

  .calculo-retiro {
    grid-template-columns: 100%;
  }

  .bg-modal-retiro {
    padding: 20px 10px 20px 10px; 
    

   }


   .retiro-1 {
    grid-template-columns: 90%;
    }

   .retiro-2 {
      grid-template-columns: 90%;
    }

    .retiro-3 {
      grid-template-columns: 90%;
    }



}