.monto-registrar {
    background: rgb(13, 57, 35);
    padding: 12px 9px 12px 9px !important;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.42) !important;
    border: 1px solid rgb(4, 123, 67) !important;
    border-radius: 2px;
}


.custom-file-panther {
    position: relative !important;
}


.load-file {
    display: grid;
    background: rgba(1, 35, 121, 0.872);
    color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    border:1px solid #fff;
}