.spinner-box {
    height:100%; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    align-items: center;
}


.height-fix-saldos {
    min-height: 69px;
}



.height-fix-ultimos-movimientos {
    min-height: 400px;
}v

.height-fix-productos-inversion {
    min-height: 190px !important;
    align-content: center;
}

.spinner {
    width: 20px;
    height: 20px;
    
    background-color: #fff;
  
    border-radius: 100%;  
    -webkit-animation: sk-scaleout 0.5s infinite ease-in-out;
    animation: sk-scaleout 0.5s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.2);
      opacity: 0;
    }
  }
  
  @keyframes sk-scaleout {
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      opacity: 0;
    }
  }