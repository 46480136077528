// aqui vamos a poner el stilo del body del modal



.modal-responsive-fix {
        background: rgba(46, 4, 12, 0.79);
        position: fixed;
        z-index: 1300;
        inset: 0px;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column; 

        @media screen and(min-width:0px) and (max-width:500px) {
      
            overflow: scroll;
            
          }
        
        
    }

.fix-cancelarcuenta-title{ 
    padding: 2rem;
    h5 {
        font-size: 2.2rem;
        line-height: 3rem;
    }
} 

.cancel-ofert-body {
    line-height: 3.5rem;
    padding: 2rem;
    font-size: 30px;
    font-weight: 500;
    margin:0;
    span {
        font-weight: 900;
    }

}

.btn-cancel-justify-center {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Modal-oferta { 

    width: 50%;
    
  @media screen and(min-width:0px) and (max-width:1200px) {
      
    width: 92%;
    
  }


} 

// aqui vamos a poner el stilo del body del modal



/// vamos a hacer el modal responsive
.modal-responsive-fix-invertir {
     
  background-position: 40% 45%;
  background-size:100%;
  background-repeat:no-repeat; 
  transition: all 500ms ease;
  opacity: 1.00;
    position: fixed;
    z-index: 1300;
    inset: 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%; 
    
    

       
    @media screen and(min-width:0px) and (max-width:992px) {
      
        justify-content: start;
        height: 100%; 
      }
} 

.proyect-icon {
    font-size: 1.5rem;
    text-decoration: capitalize;
    margin: 1.5rem !important; 
    i {
        background: rgb(5, 76, 138);
        transition: all 500ms ease;
        border-radius: 3px;
        color: #fff;
    }

    i:hover{
        color: rgb(0, 200, 255);
        box-shadow: 0px 0px 24px rgb(21, 8, 171);
        transition: all 500ms ease;
    }


    
    @media screen and(min-width:0px) and (max-width:500px) {
      
        font-size: 1rem;
        font-weight: 300;
        margin: 1rem 1rem 1rem 1rem !important;
        text-transform: uppercase;
      }


} 



.display-4 {

          
    @media screen and(min-width:0px) and (max-width:500px) {
      
        font-size: 1.4rem;
        margin-bottom: 0px;
        
      }


    

} 

.display-5 {

          font-size: 1.2rem; 

          @media screen and(min-width:0px) and (max-width:500px) {
      
            font-size: 0.7rem; 
            margin-bottom: 0px;
            
          }
    


    

}

/// fuente del monto disponible

.font-disponible {
    font-size: 2rem;
    font-weight: 900;
    color: #ffd080 !important;
text-shadow: 0px 0px 1px #ffcb00, 0px 0px 43px #000;
} 

// aqui vamos a contaner todco del modal 
//de mis productos de inversion
//esto va a contener toda la caja

.invertir-fix-mobile {



    @media screen and(min-width:0px) and (max-width:990px) {
        overflow:scroll;
        width: 100%;
        
      }
  }  


  .distribucion-contrato-footer-title { 
    @media screen and(min-width:0px) and (max-width:500px) {
      
        font-size: 25px !important;
        
      }
    
} 


.card-body { 


    @media screen and(min-width:0px) and (max-width:500px) {
      
        padding: 0rem !important;
        
      }
    
}



.app-panther-bar { 
    align-items: center;
    font-size: 12px !important;
    margin:10px 25px 10px 25px !important;

    @media screen and(min-width:0px) and (max-width:500px) {
        width: 93% !important;  
        padding: 0rem !important;
        font-size:7px !important;
        color: #fff;
        
      }
    
} 



.start-bar {
  align-items: start !important;
}



  
  .responsive-width-fix{
      width: 90%; 

      @media screen and(min-width:0px) and (max-width:500px) {
        
    align-items: center;
    display: flex;
    flex-direction: column;

        width: 100%; 
        
      }
  } 


  /// este es el height responsive 
  .min-height {
      
    @media screen and(min-width:0px) and (max-width:500px) {
      
        min-height: 700px;
      }

  } 

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, 
  .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, 
  .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, 
  .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8,
   .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, 
   .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, 
   .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, 
   .col-md-10, .col-md-11, .col-md-12, .col-md, 
   .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, 
   .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, 
   .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, 
   .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, 
   .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, 
   .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, 
   .col-xl-auto { 

    @media screen and(min-width:0px) and (max-width:500px) {
      
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 1px;
        padding-left: 1px;
        align-items: center;
      }



} 

.card {
  @media screen and(min-width:0px) and (max-width:500px) {
      
    padding: 0px !important;
  }

  @media screen and(min-width:500px) and (max-width:768px) {
      
    padding: 30px;
  }
}
    

/// vamos a hacer una ajyuda a la caja modal responsive de proyectos


.help-box-proyectos div{
   

    @media screen and(min-width:0px) and (max-width:500px) {
      
      padding: 6px 4px 5px 4px !important;
    }
    

} 




/// merdo secundario


.span-click{
  text-decoration: underline;
} 

.span-click:hover{
  text-decoration: underline;
  color:#ffcb00;
  transition: all 500ms ease;
} 

.mercadosec-title {
  letter-spacing: 1px;
  
} 


.mercado-style {
font-size: 20px;
  letter-spacing: 1px;
  background: rgb(25, 0, 248);
}  


.mercado-style-primario {
  font-size: 20px;
    letter-spacing: 1px;
    background: #139387;
  } 
  
  

 .modal-direcciones{
   padding: 50px;
   h1, h3 , h5 , h2 {
     margin:0px !important;
     margin-top:10px !important;
     margin-bottom:10px !important;
     text-align: center; 


     
  
   }


   a {
     background:#00f7a1 !important;
     color:#000;
     font-weight: 900;
     transition: all 500ms ease;
   }


   .div-match { 


    background: linear-gradient(to left, #010b43de, #0f306c), url(./Panther-pattern-7.png) !important;
    background-position: 50% 60% !important;
    background-size:100% !important;
    background-repeat:no-repeat !important;
  border:1px solid #0070ff !important;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 0px 5px #000;
  margin-top: 30px;
  // background: rgb(2, 18, 96);
  display:flex;
  align-items: center;
  flex-direction: column; 
  z-index: 999999;


  @media screen and(min-width:0px) and (max-width:500px) {
    margin:0px;
   
  }
  
}

 } 

 .z-index-incremento {
   z-index: 1 !important;
 } 






 ////////////////////// aqui vamos a colocar el grid mode //////////////////////
 ////////////////////// aqui vamos a colocar el grid mode //////////////////////
 ////////////////////// aqui vamos a colocar el grid mode //////////////////////
 ////////////////////// aqui vamos a colocar el grid mode //////////////////////
 ////////////////////// aqui vamos a colocar el grid mode //////////////////////
///// MODAL PRIMARIO PRO /////
///// MODAL PRIMARIO PRO /////
///// MODAL PRIMARIO PRO /////


.grid-container-modal-pro {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-auto-flow: row;
  align-items: center;
  position: relative;
  
  
   

  .elemento-pro-1{  
    display: grid;
    grid-auto-flow: row;
    padding: 6px; 


      h2{ 
        font-weight: 900;
        margin-bottom: 0px;
        margin-top: 7px ;
      }

    .grid-pro-datos {
      display:grid;
      row-gap: 3px;
      column-gap: 3px;
      grid-template-columns: 33% 33% 33%;

      p {
        background: rgb(4, 30, 109);
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        align-items: center;
        display: grid;
        margin-bottom: 0px;
        transition:all 500ms ease;
      }
      p:hover{
        background: rgb(0, 100, 250);
        transition:all 500ms ease;
      }


      strong {
        font-weight: 900;
      }

    } 


    .grid-pro-btn {
      display:grid;
      row-gap: 3px;
      column-gap: 3px;
      grid-template-columns: 50% 50%;
      grid-auto-rows: 20px;
      grid-auto-flow: row;
      align-items: center;

      p {
        background: rgb(4, 30, 109);
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        align-items: center;
        display: grid;
        margin-bottom: 0px;
        transition:all 500ms ease;
      }
      p:hover{
        background: rgb(0, 100, 250);
        transition:all 500ms ease;
      }


      strong {
        font-weight: 900;
      }  


      div {
        display: grid;
        
        align-items: center;
        height: 100%;
      }


      button {
        margin: 0px;
        background: rgb(4, 30, 109) !important;
        padding: 7px 3px 7px 3px;
      }
      button:hover {
        background: rgb(6, 166, 252) !important;
        
      }

    }


  } 


  .border-green-bottom {
      border-bottom:4px solid #00f7a1;
      border-radius:7px 7px 0px 0px !important;
  }

  .border-blue-bottom {
    border-bottom:4px solid #0046f7;
    border-radius:7px 7px 0px 0px !important;
  }

  .border-blank-bottom {
    border-bottom:2px solid #00f7a100
  }



  .elemento-pro-2{  
    display: grid;
    grid-auto-flow: row;
    padding: 11px; 
    position: relative;

    h2{ 
      font-weight: 900;
      margin-bottom: 0px;
      
      text-align: center;
    } 

    p {
      text-align: center;
      margin-bottom: 0px !important;
    }

    h5 {
      text-align: center;
      color: #fff;
      margin-bottom: 15px !important;
    }

    .chart-grid-area {
      display: grid; 
      height: 200px !important;
      
      align-items: center;
      justify-content: center;
      border:1px solid rgb(0, 123, 255);
      border-radius: 4px;
      
      canvas {
        height: 160px !important;
        width:320px !important;
      
      }

    } 

    

    .chart-grid-area-label {
      display:grid;
      grid-template-columns: 33% 33% 33%; 
      column-gap: 3px !important; 
      align-items: center;
        justify-content: center;

      .element-label-area{
        background: #041e6d;
        border-radius: 7px;
        padding: 5px;
        margin-top: 10px;
        width: 100%; 
        display: grid;
        transition: all 500ms ease;

        i {
          text-align: center;
          font-weight: 900;
          font-size: 22px;
          color: rgb(0, 102, 255);
          transition: all 500ms ease;
        }

        small {
          border-bottom:1px solid rgb(5, 79, 189);
          padding-left: 13px;
          padding-right: 13px;
          width: 100%;
          text-align: center;
          color: #fff;
        }

        p {
          width: 100% !important;
          text-align: center;
          margin-bottom: 0px; 
          

            span {
              font-weight: 900;
            }


          
        }
        strong {
          width: 100% !important;
          text-align: center;
          font-weight: 900;
          font-size: 17px;
          color: #fff;
        }
        


      } 

      .element-label-area:hover{
        background: #0064fa;
        transition: all 500ms ease;

        i {
          color: #fff;
          transition: all 500ms ease;
        }
      }



    } 



    .video-show-control {
      display: grid;
      grid-template-columns: 100%;
      height: 100%;
      padding-top: 10px;
      


      p {
        color:#f7c738;
        font-size: 20px;
        font-weight: 500;
        background: #041e6d;
        transition: all 200ms ease;
        border-radius: 3px;
        border-bottom: 1px solid #041e6d;
        height: 40px;
        
        align-items: center;
        cursor: pointer;
        white-space: nowrap !important;
        display: grid;
        justify-content: center;


        i {
          background: #133dbb;
          height: 30px;
          width:30px;
          display: grid;
          align-items: center;
          border-radius: 50%;
          padding-left: 5px;
          font-size: 17px;
          position: relative;
        }
        i::after{
          content: "Reproducir Video DEMO";
          position: absolute;
          opacity: 0;
          transition: all 200ms ease;
          bottom:-19px;
          left:-32px; 
          font-size: 10px;
          font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
          
        }
        
        
      }

      p:hover {
        transition: all 200ms ease;
        border-bottom: 5px solid #0064fa;


        i::after{
          
          opacity: 100;
          transition: all 200ms ease;
          
          
        }





      }

    }

    @keyframes superanimation-video {
          0% {
            transform:translateY(1000px);
          }
          100% {
            transform:translateY(0px);
          }
      }



      .video-show::before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background:  linear-gradient( to bottom, rgba(0, 0, 0, 0.186) 90% , rgb(0, 0, 0) );
        z-index: 0;
        display: grid;
        border-radius: 9px;
      }



    .video-show {
      display: grid;
      grid-template-columns: 100%;
      position: absolute;
      top:0;
      left:0;
      height: 100%;
      align-items: center;
      background: #041e6d;
      border-radius: 10px;
      opacity: 0;
      z-index: -1;
      
      
    
      i {
        position: absolute;
        font-size: 20px;
        color: #fff;
        right: 10%;
        top: 0;
        background:#0064fa;
        height: 50px;
        width: 40px;
        display: grid;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 100ms ease;
        border-bottom:1px solid #0064fa;
        z-index: 2;
      }

      i:hover {
        height: 50px;
        transition: all 100ms ease;
        border-bottom:1px solid #e6ad00 ;
        color: #e6ad00;
      }

      video {
        width: 98%;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        filter: blur(0px);
        border-radius: 2px;
        box-shadow: 0px 0px 4px #000;
      }
      
      video:hover {
        filter: blur(20px);
        transition: all 100ms ease ; 
      }
    }

    .show-video-class {
      opacity: 100;
      z-index: 1;
      animation: superanimation-video 350ms ease-in-out 0ms 1 normal;
    }


  } 

  .elemento-pro-3{  
    display: grid;
    grid-auto-flow: row;
    padding:  51px;
    background:linear-gradient(to bottom, rgba(37, 88, 197, 0.96), rgba(0, 33, 130, 0.88)), url(./Panther-pattern-7.png) ;
    background-position: 50% 60%;
    background-size:30%;
    background-repeat:repeat;
    border-radius: 14px;
    box-shadow: 0px -10px 4px #0000 !important;  
    margin-left:6px;
    

    .saldo-dis {
      border-bottom:1px solid #00289f;
      font-size: 20px !important;
    }


    h2{ 
      font-weight: 900;
      margin-bottom: 0px;
      
      text-align: left;
    } 

    p {
      text-align: right;
      margin-bottom: 0px !important;
      
      
      border-bottom:1px solid #041e6d;
      span {
        font-weight: 900;
      }
    }

    label {
      text-align: right;
      margin-bottom: 0px !important;
      width: 100%;
      font-weight: 500;
      border-radius: 3px;
      padding:10px 5px 10px 5px;
      font-size: 19px;
    }


    h3{ 
      font-weight: 900;
      margin-bottom: 0px;
      
      text-align: left;
    } 


    .calculo-ele-3 {
      display: grid;
      grid-template-columns: 66% 35%;
      
      row-gap:4px; 

      

      p {
        
        margin-bottom: 0px;
        text-align:left;
        font-size: 14px;
        padding: 5px;
        
      } 

      strong {
        background: #0d3da59c;
        margin-bottom: 0px;
        text-align: center;
        font-size: 14px;
        padding: 5px;
        border-radius: 3px;
        color:#fff;
        border:1px solid #001a67;
      }

    }


.btn-invertir-container-grid {


    margin-top:20px;

    button {
      justify-content: center;
      align-items: center;
      display: grid !important;
      margin: 0px !important;
      transition: 500ms all ease;
  }
  
    p {
      color: #000;
      text-align: center;
      width: 100% !important;
      margin: 0px !important;
      transition: 500ms all ease;
      border-bottom:1px solid #fff;
      transition: all 500ms ease;
    }
    transition: all 500ms ease;

    

  } 

  .btn-invertir-container-grid:hover {
    p {
      border-bottom:1px solid rgb(3, 5, 55) !important;
    }
  }
    





  }

} 




.d-grid {
  display: grid !important;
}

.mercadoprimario-header {
  text-align: center;
  background: #000;
  font-weight:900;
  font-size: 20px !important;
  padding: 10px; 
  border-radius: 30px 30px 0px 0px ;


  background:linear-gradient(to bottom, rgba(16, 56, 141, 0.633), rgba(6, 26, 60, 0.845)), url(./Panther-pattern-7.png) ;
  background-position: 50% 60%;
  background-size:30%;
  background-repeat:repeat;
  margin-bottom: 0px !important;


  span {
    font-weight: 400;
    font-size: 13px;
  }
}


.header-container-grid-modal { 

  display: grid;
  grid-template-columns: 80% 20%;
  grid-auto-flow: row;
  align-items: center;
  
  
  
  
  
  .elemento-modal-1-header {

    background: linear-gradient(to left, rgba(16, 56, 141, 0.633), rgba(6, 26, 60, 0.845));
    padding-left:30px ;

    display: grid;
    justify-content: left;

     h3 {
       margin:0px 0px 0px 0px ;

       span {
         font-weight: 900;
         font-size: 15px;
         padding-bottom: 3px;
         border-bottom: 1px solid #0070ff;

       }
       
     }

  }

  .elemento-modal-2-header {
    display: grid;
    justify-content: center;
    text-align: center;
    padding: 0px !important;
    margin: 0px !important;
    border-radius: 100px 0px 0px 100px !important;
    border:1px solid #000;



    i {
      font-size: 20px;
    }

  }
  

}



/////////////////////////
/////////////////////////
/////////////////////////

.blur-master{
  filter: blur(13px);
}


.blur-master::after {
  height: 100%;
  width: 100%;
  content:"";
  position: absolute;
  background: linear-gradient( to bottom, rgba(0, 0, 0, 0.261), #000);
}

.grid-list-modal {
  display:block;
  width: 100%;
  height: 100%;
  padding-top: 3px;
  position: absolute;
  top:0px;
  z-index: 0;
  
  
} 

.z-index-hd {
  z-index: 4444 !important;
}

.height-0 {
  height: 0px !important;
}

.grid-list-modal h3 {
  text-align: center;
  width: 100%;
  display: grid;
  margin:0;
  align-items: center;
  height:27px;
}

.grid-list-modal p {
  text-align: center;
  width: 100%;
  display: grid;
  margin:0;
  align-items: center;
  height:28px;
  cursor:pointer;
  background: #041e6d;
  
  
  white-space: nowrap;
  grid-auto-flow: column;
  justify-content: center;

  .icon-vis-style{
    height: 20px !important; 
    margin-right: 4px !important;
  }

}

.header-grid-asociadas {
  display: grid;
  grid-template-columns: 3% 18.3% 7.3% 7.3% 8.3% 6.3% 6.3% 6.3% 6.3% 7.3% 8.3% 8.3% 6.6%;
  position: relative;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 10px !important;
  margin-bottom: 32px;
  
  

  div {
    background: linear-gradient(to bottom, #ccdfff, #e9eef2), url(./Panther-pattern-7.png) ;
       background-position: 50% 60% !important;
       background-size:100% ;
       background-repeat:no-repeat ;
      filter: saturate(70%);
    color :#000935;
    border-radius: 1px !important;
    padding: 6px;
    text-align: center;
    display: grid;
    align-items: center;
    grid-auto-flow: row;
    justify-content: center;
    text-align: center;
    line-height: 13px;
    transition: all 500ms ease;
    border-right:1px solid #ffb700 !important;
    box-shadow: 0px 10px 10px #000;
  } 

  strong {
      
    display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 14px; 
  transition: all 500ms ease;
  font-size: 13px ;

  /// esto es despues de las querys

  //  @media screen and(min-width:1710px) and (max-width:3350px) {
  //      font-size: 10px ;
  //  } 

  @media screen and(min-width:992px) and (max-width:1199px) {
      font-size: 7px ;
  } 

  @media screen and(min-width:1200px) and (max-width:1399px) {
    font-size: 8px ;
} 

  @media screen and(min-width:1400px) and (max-width:1710px) {
    font-size: 10px ;
} 


  } 

  span {

    font-family: 900;
    color: #fff;
    transition: all 500ms ease;
  } 


  small {

    color: #fff;
    font-weight:  400;
    font-size:8px;
    width: 100%;
    margin-top: 7px;
    transition: all 500ms ease;
  } 


}

.list-box-overflow{
  height: 0px;
  border-radius: 0px 0px 20px 20px;
  display: block;
  width: 100%;
  overflow-y: scroll;
}

.list-box-overflow-open {
  height: 80%;
}



.header-grid-asociadas-body:hover {
  div {
  background: #1441c6;
    
  color :#ffffff;
  border-radius: 0px !important;
  padding: 6px;
  
  justify-content: center;
  text-align: center;
  line-height: 13px;
  transition: all 500ms ease;
  border:1px solid #1441c6 !important;
  cursor: pointer;
  }
} 

.header-grid-asociadas-body {
  display: grid;
  grid-template-columns: 3% 18.3% 7.3% 7.3% 8.3% 6.3% 6.3% 6.3% 6.3% 7.3% 8.3% 8.3% 6.6%;
  position: relative;
  
  
  width: 100%;
  min-height: 10px !important;
  justify-content: center;


  div {
    background: #041e6d;
      display: grid;
    color :#ffffff;
    border-radius: 0px !important;
    padding: 6px;
    
    justify-content: center;
    text-align: center;
    line-height: 13px;
    transition: all 500ms ease;
    border:1px solid #041e6d !important;
    cursor: pointer;
  } 




  strong {
      
    display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 14px; 
  transition: all 500ms ease;
  font-size: 13px ;
  font-weight: 300;

  /// esto es despues de las querys

  //  @media screen and(min-width:1710px) and (max-width:3350px) {
  //      font-size: 10px ;
  //  } 

  @media screen and(min-width:992px) and (max-width:1199px) {
      font-size: 7px ;
  } 

  @media screen and(min-width:1200px) and (max-width:1399px) {
    font-size: 8px ;
} 

  @media screen and(min-width:1400px) and (max-width:1710px) {
    font-size: 10px ;
} 


  } 

  span {

    font-family: 900;
    color: #fff;
    transition: all 500ms ease;
  } 


  small {

    color: #fff;
    font-weight:  400;
    font-size:11px;
    width: 100%;
    margin-top: 7px;
    transition: all 500ms ease;
  } 


}





/////////////////////////
/////////////////////////

// aqui vamos a colocar las parcelas ///
/////////////////////////

.box-parcelas {
  display: grid;
  grid-template-columns: 100%;
  position: relative;
}

.parcela-info {
  padding: 0px 5px 0px 5px;

  h3 {
    font-size:16px;
    text-align: left !important;
    margin:0px !important;
    font-weight: 900;
  }

  h4 {
    font-size:11px;
    text-align: left !important;
    margin:0px !important;

    span {
      font-weight: 900;
      padding-left: 6px;
      font-size:15px;
    }


  }

}

.parcelas-aso {
  display:grid;
  position:relative;
  grid-template-columns:66% 33%;
  align-items: center;
  margin-top: 7px;

    h2 {
      margin: 0px !important;
    }

    svg {
      font-size:17px !important;
    } 

    p {
        background: #2203ff;
        color:#fff;
        padding: 3px 5px 3px 5px ;
        text-align: center;
        border-radius: 2px;
        transition: all 200ms ease;
    }

    
    p:hover {
      transition: all 200ms ease;
      background: rgb(20, 189, 104);
      color : #fff;
      cursor: pointer;
      
  }


} 

.text-aso{
      strong {
        font-weight:800;
    } 
}

.active-parcelas  {
  margin: 30px 0px 30px 0px;
  transform: scale(0.99);
  border: 1px solid #00f7a1;
  border-radius: 6px;
  animation: animation-border 800ms ease-in-out 0ms infinite alternate;
  box-shadow: 0px 8px 20px #000;
  div {
    background: none!important;
    border: 1px solid #98fc8b00 !important;
  }
  
}


.active-parcelas:hover {
  div {
    border: 1px solid #98fc8b00 !important;
  }
}


@keyframes animation-border { 
  0% {
    border: 1px solid #00f7a1;
  } 
  100% {
    border: 1px solid #ffe100;
  }

} 



/////////////// animacion del modal , de las entradas 
//// la seleccion /////


.animation-fx-modal-1 {

  animation:animation-up-modal 350ms ease 0ms 1 normal;
} 

.animation-fx-modal-2 {

  animation:animation-up-modal 1550ms ease 0ms 1 normal;
} 

.animation-fx-modal-3 {

  animation:animation-up-modal 1850ms ease 0ms 1 normal;
} 


@keyframes animation-up-modal {
  from{
    opacity: 0;
    transform:translateY(-40px)
  }
  to {
    opacity: 1;
    transform:translateY(0px)
  }
}


.animation-forma-block-btn {
  animation:open-btn-lag 550ms ease 0ms 1 normal;
}



@keyframes open-btn-lag {
  from{
    opacity: 0;
    display: none;
    
  }
  to {
    opacity: 1;
    display: block;
  }
}

// @keyframes animation-up-modal {
//   from{
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// @keyframes animation-up-modal {
//   from{
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }


.panther-calientes {
  
  height: 3px;
  width: 98%;
  position: absolute;
  left: 17px;
  border-radius: 3px;
  //  border-right:1px solid #f70019 ;
  //  border-top:1px solid #f70019 ;
  background: linear-gradient( to right, rgba(230, 3, 64, 0.467), rgb(213, 11, 72));
  z-index: 0;
  filter: saturate(300%);


  i {
    color: #fff;
    font-size: 20px;
    position: absolute;
    left:5px;
    top:-1px;
    z-index: 1;
  }


}

.panther-calientes::after{
  content:'';
  position:absolute;
  height: 30px;
  width:30px;
  top:-7px;
  left:0px;
  background:linear-gradient( to right, rgba(230, 3, 64, 0.467), rgb(213, 11, 72));
  border-radius: 2px;
  z-index: -1;
}

.panther-calientes-verdes {
  
  height: 3px;
  width: 98%;
  position: absolute;
  left: 17px;
  border-radius: 3px;
  //  border-right:1px solid #f70019 ;
  //  border-top:1px solid #f70019 ;
  background: linear-gradient( to right, rgba(3, 230, 200, 0.556), rgb(0, 8, 255));
  z-index: 0;
  filter: saturate(300%);


  i {
    color: #fff;
    font-size: 20px;
    position: absolute;
    left:5px;
    top:-1px;
    z-index: 1;
  }


}

.panther-calientes-verdes::after{
  content:'';
  position:absolute;
  height: 30px;
  width:30px;
  top:-7px;
  left:0px;
  background: linear-gradient( to right, rgba(3, 230, 200, 0.556), rgb(0, 8, 255));
  border-radius: 2px;
  z-index: -1;
}

.panther-calientes-war {
  
  height: 3px;
  width: 98%;
  position: absolute;
  left: 17px;
  border-radius: 3px;
  //  border-right:1px solid #f70019 ;
  //  border-top:1px solid #f70019 ;
  background: linear-gradient( to right, rgba(67, 41, 3, 0.905), rgb(213, 142, 11));
  z-index: 0;
  filter: saturate(300%);


  i {
    color: #fff;
    font-size: 20px;
    position: absolute;
    left:5px;
    top:-1px;
    z-index: 1;
  }


}

.panther-calientes-war::after{
  content:'';
  position:absolute;
  height: 30px;
  width:30px;
  top:-7px;
  left:0px;
  background: #ffb007;
  border-radius: 2px;
  z-index: -1;
}






/// aqui vamos a colocar las clases para hacer las mas calientes

.animation-up-proyectos {
    animation: null;
}



@keyframes superopen {
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}


.instrucciones-semaforo{
  display: grid;
  grid-template-columns:  30% 30% 30%;
  height: 100%;
  width: 100%;
  background:#00134b3a;
  border-bottom:1px solid rgb(255, 216, 143);

  justify-content: center;
  padding: 39px;
  column-gap:23px;
  position: relative;
  border-radius: 2px;
  animation: superopen 200ms ease 0ms 1 alternate;

 @media screen and (min-width:0px) and (max-width:992px) {
  grid-template-columns: 100%;
  row-gap: 10px;
  padding: 10px;
  img {
    width: 150px;
    height: 25px;
    position: absolute;
    
    display: grid;
    top:20px;
    right: 20px;
  }
 }

  

  

  img {
    width: 200px;
    height: 33px;
    position: absolute;
    
    display: grid;
    top:20px;
    right: 20px;
  }
  

  i{
    display:grid;
    background: #fff;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 50px;
    box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.385);
  }

  h2, h3, p {
    margin: 2px !important;
  }

  h3{
    font-size: 30px;
    transition: all 100ms ease;
    font-weight: 900;
  }

  p {
    font-size: 14px;
    transition: all 100ms ease;
    
  }

  div {
    padding: 20px;
    
    position: relative;
    border-radius: 4px;
    filter: saturate(50%);
    transition: all 200ms ease;

  }

  div:hover {
    filter: saturate(100%);
    transition: all 200ms ease;
    transform: scale(0.95);
    
  h3{
    font-size: 30px;
    font-weight: 900;
    transition: all 100ms ease;
  }

  p {
    font-size: 14px;
    transition: all 100ms ease;
  }



  }


  .semaforo-2 {
    background: linear-gradient( to right, rgba(255, 173, 21, 0.624), rgba(237, 138, 62, 0.433));
    
  }

  .semaforo-3 {
    background: linear-gradient( to right, rgba(3, 169, 230, 0.556), rgba(0, 60, 255, 0.22));
  }

  .semaforo-1 {

    background: linear-gradient( to left, rgba(230, 3, 64, 0.275), rgb(213, 11, 72));

  }

}


.card-bg-master {
  background: none !important;
  border: 1px solid rgba(232, 148, 13, 0.457) !important;
  border-radius: 6px;
  position: relative;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  
}

.card-bg-master::before {
  background: url('../../../Landing/Danni_Panther01.jpg');
  border-radius: 6px;
  background-size: 150% !important;
  background-repeat: repeat !important;
  background-position: 70% 20%;
  z-index: -1 !important;
  position: absolute !important;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  content: '';
  filter: blur(30px) saturate(60%);
  overflow-y: hidden !important;
}



.card-bg-master::after {
  background: linear-gradient( to bottom right, rgba(0, 17, 50, 0.807), rgba(1, 8, 30, 0.141), rgb(0, 0, 0));
  border-radius: 6px;
  background-size: 150% !important;
  background-repeat: repeat !important;
  z-index: -1 !important;
  position: absolute !important;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  content: '';
  overflow-y: hidden !important;
}


.card-bg-master p {
  
  border-bottom:1px solid rgba(232, 148, 13, 0.457) !important;
  padding-bottom: 10px;
  height: 130px;
  display: grid;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-shadow: 5px 3px 4px #000;
  font-size: 30px;
}

.card-bg-master button {
  background: #011953b7 !important;
  border:1px solid rgba(232, 148, 13, 0.544) !important;
  border-radius: 2px;
  transition: 140ms ease;
  width: 142px !important;
  margin-left: auto;
  margin-right: auto;
  
}

.card-bg-master button:hover {
  background: #0e43c997 !important;
  border:1px solid rgba(232, 148, 13, 0.544) !important;
  transition: 140ms ease;
  box-shadow: none !important;
  transform: none !important;
  width: 142px !important;
  margin-left: auto;
  margin-right: auto;
}


.card-bg-master .card-footer {
  background: none !important;
  border:none !important;
  overflow-x: hidden !important;
}


.card-bg-master .card-header {
  width: 100%;
}



 