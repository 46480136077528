

.loading-fx-null {
    animation: none !important;
}


.loading {
    display: grid;
    grid-template-columns: 100%;
    // background: #05284e;
    background: linear-gradient(to bottom, #00694d96, #083832, #3a885485),url(./Panther-pattern-7.png),#000000;
    position: absolute;
    z-index: 99999;

    height: 100%;
    width: 100%;

    justify-content: center;
    align-items: stretch;
    animation: openload 400ms ease-in-out 0ms 1 normal;
    
    


    div {
        margin:0;
        padding: 0;
    }

    p {
        
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 18px;   
        padding-top: 15px;
        animation: p-loading 1200ms ease-in-out 0ms infinite alternate;
    }


    img {
        opacity: 0.90;
        

    } 

    .elemento1{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative !important;
        z-index: 2;
        


        img {
            width:360px;
            height: auto;
            margin-bottom:10px;
            animation: img-loading 2900ms ease-in-out 0ms infinite alternate;
        }

        .img-flat {
            animation: img-loading-2 600ms ease-in-out 0ms infinite alternate;
            position: absolute;
        }

        
    } 

    .elemento2{
        z-index: 2;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }


    .bar {
        width:360px;
    height: 3px;
    content:"";
    background: rgb(41, 103, 218);
    position: relative;
    display: flex;
    bottom:-10px;
    border-radius: 30px;
    opacity: 0.90;
    
    animation: blur-loading 666ms ease-in-out 0ms infinite alternate;
    }
    


    .bar::after {
        
        height: 3px;
        content:"";
        background: rgb(127, 250, 197);
        position: absolute;
        display: flex;
        bottom:0px;
        left:0;
        border-radius: 30px;
        
        animation: loading-ani 10000ms linear 0ms infinite normal;
        
    } 


    .bar::before {
        
        height: 3px;
        content:"";
        background: rgba(127, 250, 197, 0.391);
        position: absolute;
        display: flex;
        bottom:0px;
        left:0;
        
        animation: loading-ani 1000ms linear 0ms infinite normal;
    } 

    @keyframes openload {
        from {

            

                opacity: 0;
                transform: scale(0.10);


            

            
        }

        to {

            
                
                    opacity: 1;
                transform: scale(1.0);
                    
            

            
            
        }
    }


    @keyframes loading-ani {

        from {
            width:0px;
            
        }

        to{
            width:360px;
            
        }
    } 


    @keyframes p-loading {

        from {
            opacity: 0.08;
            
        }

        to{
            
            opacity: 0.40;
            
        }
    }



    @keyframes img-loading {

        from {
            filter:grayscale(60%) blur(0px);
            opacity: 0.50;
        }

        to{
            filter:grayscale(100%) blur(60px);
            opacity: 0.80;
        }
    } 

    @keyframes img-loading-2 {

        from {
            
            opacity: 0.50;
        }

        to{
            
            opacity: 0.80;
        }
    } 



    @keyframes blur-loading {

        from {
            filter:saturate(140%) blur(6px);
            
        }

        to{
            filter:saturate(140%) blur(0px);
            
        }
    }





    // .elemento1::before {
    //     width:280px;
    //     height: 4px;
    //     content:"";
    //     background: rgb(32, 79, 167);
    //     position: absolute;
    //     display: flex;
    //     bottom:-10px;
    // }


    .elemento3 {
        display: flex;
        
        span {
            background: #000;
            height: 3px;
            width: 100px;
            display: flex;
        }
    }


   
} 


.bg-particle-media-load::after{
    height: 100%;
    width: 100%;
    position: absolute;
    content:'';
    top: 0px;
    left: 0px;
    
    
    z-index: 0;
}

.bg-particle-media-load{
    display: flex;
    z-index: 0;
    
    align-items: center;
    
    
    @media screen and (min-width:0px) and (max-width:992px){
        display: flex;
    }

    #tsparticles {
        height:99% !important;
        opacity: 0.69;
        
    }
}




