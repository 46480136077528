[data-tooltip]:hover {
	position: relative;
    font-size: 10px;
    transition: all 500ms ease;
    color: #fff99e;
    text-shadow: 0px 0px 1px rgb(230, 159, 6);
    cursor: pointer;
    letter-spacing: 0px;
    overflow:visible;
}
[data-tooltip]:hover::before {
    transition: all 500ms ease;
	all: initial;
	font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
	display: inline-block;
	border-radius: 8px;
	padding: 5px;
	background: #0d1949;
    border: 1px solid rgb(25, 87, 245);
    box-shadow: 0px 0px 30px #000;
	content: attr(data-tooltip);
	color: #f9f9f9;
	position: absolute;
	bottom: 0px;
	width: 280px;
    height: 30px;
    align-items: center;
    display: grid;
	left: 74%;
	transform: translate(-50%, 0);
	margin-bottom: -15px;
	text-align: center;
	font-size: 11px;
    z-index: 99999; 
    animation: bg-tooltips 1000ms ease-in-out 0ms infinite alternate;
    cursor: pointer;
    overflow:visible ;
} 



[data-tooltipdos]:hover {
	position: relative;
    font-size: 10px;
    transition: all 500ms ease;
    color: #2d2a05;
    
    cursor: pointer;
    letter-spacing: 0px;
    overflow:visible;
}


[data-tooltipdos]:hover::before {
    transition: all 500ms ease;
	all: initial;
	font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
	display: inline-block;
	border-radius: 8px;
	padding: 5px;
	background: #6d5c2a;
    
    border:1px solid rgb(190, 146, 14);
	content: attr(data-tooltipdos);
	color: #f9f9f9;
	position: absolute;
	bottom: 0px;
	width: 280px;
    height: 30px;
    align-items: center;
    display: grid;
	left: 54%;
	transform: translate(-50%, 0);
	margin-bottom: 25px;
	text-align: center;
	font-size: 11px;
    z-index: 99999; 
    
    cursor: pointer;
    overflow:visible ;
} 


//////////////////////////////////////////////////////////


[data-tooltiptres]:hover {
	position: relative;
    font-size: 10px;
    transition: all 500ms ease;
    color: #2d2a05;
    
    cursor: pointer;
    letter-spacing: 0px;
    overflow:visible;
}


[data-tooltiptres]:hover::before {
    transition: all 500ms ease;
	all: initial;
	font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
	display: inline-block;
	border-radius: 8px;
	padding: 5px;
	background: #6d5c2a;
    
    border:1px solid rgb(190, 146, 14);
	content: attr(data-tooltiptres);
	color: #f9f9f9;
	position: absolute;
	bottom: 0px;
	width: 280px;
    height: 30px;
    align-items: center;
    display: grid;
	left: 54%;
	transform: translate(-50%, 0);
	margin-bottom: -79px;
	text-align: center;
	font-size: 11px;
    z-index: 99999; 
    
    cursor: pointer;
    overflow:visible ;
} 






[data-tooltipblank]:hover {
	position: relative;
    font-size: 10px;
    transition: all 500ms ease;
    color: #fff99e;
    text-shadow: 0px 0px 1px rgb(230, 159, 6);
    cursor: pointer;
    letter-spacing: 0px;
    overflow:visible;
}
[data-tooltipblank]:hover::before {
    transition: all 500ms ease;
	all: initial;
	font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
	display: inline-block;
	border-radius: 2px;
	padding: 5px;
	background: #5aa0fc35;
    border: 1px solid rgb(207, 207, 207);
    
    
	content: attr(data-tooltipblank);
	color: #f9f9f9;
	position: absolute;
	bottom: -13px;
	width: 80px;
    height: 20px;
    align-items: center;
    display: grid;
	left: 64%;
	transform: translate(-50%, 0);
	margin-bottom: -15px;
	text-align: center;
	font-size: 11px;
    z-index: 99999; 
    
    cursor: pointer;
    overflow:visible ;
} 













th {
    transition: all 500ms ease;
    line-height: 0.9rem;
}


@keyframes  bg-tooltips {

    0%{
        
        background:#005eff ;

    }

    100%{
      
        background:#06045c ;
      
    }

  }


  .display-currency{
      font-size: 1.3rem;
      background: #005eff;
      animation: bg-tooltips 2000ms ease-in-out 0ms infinite alternate;
      align-items: center;
        display: flex;
        border-radius: 60px;
        padding: 10px 20px 10px 20px;
        margin: 10px 0px 10px 0px;
        transition: all 500ms ease;
        border: 1px solid rgb(25, 87, 245);
        @media screen and(min-width:0px) and (max-width:820px) {
            margin: 10px 0px 10px 0px;
            padding: 7px 15px 7px 15px;
            font-size: 0.9rem;
            
          }
  } 

  .display-currency:hover {
      transform:scale(0.99);
      transition: all 500ms ease;
      cursor: pointer;
      box-shadow: 0px 0px 7px #000;

  }



.currency-bold {
    padding: 10px 30px 10px 30px;
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 4px;
    text-shadow: 0px 0px 7px rgb(12, 106, 168);

    
    @media screen and(min-width:0px) and (max-width:820px) {
        padding: 1px 4px 1px 4px;
        font-size: 1rem;
        
      }
}  


/// boton de datos bancarios fix
.datos-bancarios-btn {
    padding: 10px !important;
    background: #000 !important;
} 


.deposito-bg {
    background:#000d26c7;
  background-position: 40% 45%;
  background-size:100%;
  background-repeat:no-repeat; 
} 


//// disolucion de caja

.card-box-transparent {
    background:#0000001c;
    border: 1px solid rgba(255, 255, 255, 0.2);
}


.panther-btn-ofertada {
    background: #000 !important;
} 

.panther-btn-ofertada:hover {
    background: rgb(252, 0, 46) !important;
    box-shadow: none;
} 
.panther-btn-ofertada:focus {
    background: #000 !important;
}

.panther-btn-ofertar:hover {
    background: rgb(0, 182, 76) !important;
} 

.panther-btn-ofertar:focus {
    background: rgb(0, 89, 25) !important;
} 

.ofertada {
    background: linear-gradient( to right, rgba(100, 9, 9, 0.453), rgba(224, 14, 52, 0.919)) !important;
    
    
    td {
        border: 1px solid #ff9100 !important;
        
        }


}


//// aqui vammos a colocar los numeros rojos y verdes de los saldos

.saldos-neg {
    color: rgb(232, 77, 123) !important; 
    text-shadow: 0px 0px 1px #ff0000;

    strong {
        color: rgb(240, 148, 175) !important; 
    text-shadow: 0px 0px 1px #ff0000;
    }
}


.saldos-plus {
    color: rgba(15, 235, 88, 0.925) !important; 
    text-shadow: 0px 0px 1px #00ff55;

    strong {
        color: rgba(43, 235, 132, 0.925) !important; 
    text-shadow: 0px 0px 1px #00ff55;
    }

}
 



////estilo de las banderas

.flags {
    box-shadow: 0px 0px 0px #000;
    border-radius: 100px;
    border: 31px solid rgba(7, 104, 250, 0.254);
    transition: all 500ms ease;
    
   animation: border-flags 1000ms ease-in-out 7ms infinite alternate;
} 


@keyframes  border-flags {

    0%{
        filter: saturate(0.7);
        border: 31px solid rgba(7, 104, 250, 0.254);

    }

    100%{
        filter: saturate(1);
        border: 7px solid rgb(7, 104, 250);
      
    }

} 

.height-box-fit {
    height: 45px;
} 

.flags-dad {
    background: #05045c;
    border-radius: 12px;
    padding: 10px;
    border: 1px solid #3969f7;
    transition:all 50ms ease;
}

.flags-dad:hover {
    box-shadow: 0px 0px 30px #000;
    background: #05045c5b;
    transition:all 50ms ease;
    transform: scale(1.07);
} 


///// aqui vamos a poner el texto del dashboard
.text-bold {
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 6px;
    background: linear-gradient(to bottom left, #278cff33, #0ca5e731);
    border-radius:25px;
    border: 1px solid rgb(6, 110, 171);
    box-shadow: 0px 0px 4px #000;
    cursor:pointer;

} 


// aqui vamos a colocar el estilo del scroll 
.container::-webkit-scrollbar-thumb {

    background: rgb(0, 0, 0) !important;

    border-radius: 4px !important;

}


/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */

.container::-webkit-scrollbar-thumb:hover {

    background: #000000 !important;

    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2) !important;

}


/* Cambiamos el fondo cuando esté en active */

.container::-webkit-scrollbar-thumb:active {

    background-color: #000000 !important;

} 

/* Ponemos un color de fondo y redondeamos las esquinas del track */

.container::-webkit-scrollbar-track {

    background: #0b0a0a !important;

    border-radius: 4px !important;

}


/* Cambiamos el fondo cuando esté en active o hover */

.container::-webkit-scrollbar-track:hover,

.container::-webkit-scrollbar-track:active {

  background: #1c1c1c !important;

}

input {
    outline: none !important;
} 

//  aqui vamos a colocar el beneficiario

.plus-help-panther {
    color : #a4b2dc;
    transition: all 500ms ease;
    font-size: 15px;
    background: #01043c;
    padding: 0px 10px 0px 10px;
    border:1px solid #0390f757;
    border-radius: 100px;
    white-space: nowrap;
    align-items: center;
    display: flex;
    justify-content: center;
    

    i {
        transform: rotate(45deg) !important;
        color : rgba(255, 255, 255, 0.8);    
        border-radius: 100px;
        transition: all 500ms ease;
        font-weight: 900 !important;
        text-shadow: 0px 0px 4px #000;
        margin-left: 4px;
         font-size: 15px !important;
    }
}

.plus-help-panther:hover {
    color : #39f7ab;
    transition: all 500ms ease;
    cursor: pointer;
    
    
    i {
        

        
        border-radius: 100px;
        color : #39f7ab;
        border-radius: 300px;
        transform: rotate(225deg) !important;
        transition: all 500ms ease;
        
    }

} 


.seleccione-ben {
    font-size: 30px;
} 


[data-tooltip-dos]:hover {
	position: relative;
    font-size: 10px;
    transition: all 500ms ease;
    color: #fff99e;
    text-shadow: 0px 0px 1px rgb(230, 159, 6);
    cursor: pointer;
    letter-spacing: 0px;
    overflow:visible;
}
[data-tooltip-dos]:hover::before {
    transition: all 500ms ease;
	all: initial;
	font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
	display: inline-block;
	border-radius: 5px;
	padding: 13px;
	background: #0d1949;
    border: 1px solid rgb(25, 87, 245);
    box-shadow: 0px 0px 30px #000;
	content: attr(data-tooltip);
	color: #f9f9f9;
	position: absolute;
	bottom: 0px;
	width: 50%;
	left: 74%;
	transform: translate(-50%, 0);
	margin-bottom: -45px;
	text-align: center;
	font-size: 11px;
    z-index: 99999; 
    animation: bg-tooltips 1000ms ease-in-out 0ms infinite alternate;
    cursor: pointer;
    overflow:visible ;
} 